.tag {
  border: none;
  background-color: lightgray;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-right: 10px;
  padding: 2px;
  text-align: center;
  user-select: none;
  /* display: flex; */
}

.tagInput {
  border: 1px solid lightgray;
  border-radius: 2px;
  background-color: transparent;
  margin-top: 1rem;
}

.image-preview {
  position: relative;
}

.image-preview-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  color: difference;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.tagInput ::-webkit-input-placeholder {
  text-align: center;
}

.tagInput :-moz-placeholder {
  text-align: center;
}

.selectsedClass {
  width: 100%;
  display: wrap;
  /* height: 10px; */
  overflow-x: auto;
  overflow-y: none;
}
