/*
	Template Name: Uniworkpass
	Author: TechyDevs
	Email: contact@techydevs.com
	Description: Uniworkpass - Directory & Listing HTML5 Template
	Version: 1.0
*/
/* .dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
} */

.custom-list-style {
  width: 50%;
}
.custom-list-style > li {
  margin: 0 0 0.5rem 0;
}
.custom-list-style > li::before {
  /* content: "•  "; */
  /* content: "\002022"; */
  background-color: #9b8afd;
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 2px;
  width: 8px;
  height: 8px;
}
.form-div {
  /* background-color: #fffbfb; */
  border: 1px solid #f0f0f0;
  background-color: #f7f8fb;
  /* box-shadow: 3px 3px 14px #0000001a; */
  box-shadow: 13px 13px 14px #39004c1a;
  padding: 20px 25px 25px 25px;
  border-radius: 20px;
  margin: 0 0 0 3rem;
}
.parent-form-div {
  margin: 0 0 0 3rem;
  padding: 1rem 3rem 0 3rem;
}
@media screen and (max-width: 992px) {
  .login-vector-image {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  
  .login-left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3.2rem 0 0 0;
  }
  .parent-form-div {
    margin: 0 1rem 0 1rem !important;
  }
  .form-div {
    margin-left: 0 !important;
    padding: 20px 20px 25px 20px;
    width: 100%;
  }
  .signup-form-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#myInput,
#myInput2 {
  width: 95%;
  height: 1.55rem;
  padding: 0.2rem 0.3rem;
  margin: 0.2rem 0.4rem;
  box-sizing: border-box;
  /* background-image: url(../images/chosen-sprite.png) !important; */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 12px;
  border: none;
  border: 1px solid #848484;
}

/* #myInput:focus {
  outline: 1px solid #000;
} */
.pqr {
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff !important;
  margin-top: 0.2rem !important;
}
/* .pqr:hover {
  background-image: none !important;
  background-color: #fff !important;
} */
.dropdown-new,
.dropdown-new2 {
  position: relative;
  display: inline-block;
  width: 300px !important;
  margin: 0 1rem;
  padding: 0.6rem;
  border: 1px solid #aaa;
  border-radius: 5px;
  cursor: pointer;
}
/* @media screen and (max-width: 600px) {
  .dropdown-new,
  .dropdown-new2 {
    width: 100%;
  }
} */
.location-tag {
  /* width: 219px; */
  width: 100%;
  margin: 0 0.2rem;
  color: #808996;
  font-weight: 500;
}
#myDropdown,
#myDropdown2 {
  border: 1px solid #aaa;
  border-top: none;
}
.dropdown-content,
.dropdown-content2 {
  position: absolute;
  width: 100.5%;
  min-width: 230px;
  border-radius: 0 0 5px 5px;
  /* max-height: 240px; */
  max-height: auto;
  margin: 0.3rem -0.64rem;
  padding-right: 0.35rem;
  display: none;
  /* background-color: #f6f6f6; */
  background-color: #fff;
  /* overflow-y: scroll !important; */
  /* z-index: 1; */
}
.inner-select-div {
  max-height: 280px;
  overflow-y: auto !important;
  overflow-x: hidden;
  /* margin: 0 0 0.4rem 0; */
}
.dropdown-content ul li,
.dropdown-content2 ul li {
  color: black;
  /* border: 1px solid #ddd; */
  /* border-radius: 0 0 6px 6px; */
  /* padding: 2px 9px; */
  text-decoration: none;
  display: block;
  width: 95%;
  /* padding: 0.2rem; */
  padding: 1px 3px;
  margin: -0.2rem 0.4rem;
  font-weight: 500;
  font-family: sans-serif;
  color: #636363;
}

.dropdown-content li:hover,
.dropdown-content2 li:hover {
  /* background-color: #0b76e1; */
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
/* .main-search-country > .user-chosen-new {
  width: 100%;
  position: absolute;
} */
/*------------------------------------------------------------------
[Table of contents]

	+ General
		- general Styles
		- common-style
		- Preloader
		- theme-btn
		- button-shared
		- p-relative
		- p-absolute
		- tooltips
		- badge-closed
		- section-heading
		- Back To Top
	+ Header
		- Logo
		- login
		- sign up
		- Main Navigation
		- Dropdown
		- Offcanvas menu
	+ Content
		+ Main Files
			- index.html
	+ Banner Area
			- Breadcrumb
	+ Categories
	        - All Categories
	        - All Locations
	+ Listing
	        - Listing Grid
	        - Lsiting Detail
	        - Add Listing
	+ Pages
			- User Profile
			- Dashboard
			- Invoice
			- Login
			- Pricing
			- Contact us
			- About us
			- 404 error page
	+ blog
			- Blog Grid
			- Blog Detail
	+ Footer
		+ Top Footer
			- Subscribe Form
			- Company Details
			- Quick Licks
			- Categories
			- Conctact with us
		+ Bottom Footer
		    - Copyright
		    - Terms & Conditions
		    - Privace Policy
		    - Help Center
	+ Responsive Design Styles
		    - $laptop_l: '(max-width: 1440px)';
            - $laptop_m: '(max-width: 1366px)';
            - $laptop_m_two: '(max-width: 1280px)';
            - $laptop_m_three: '(max-width: 1200px)';
            - $laptop_m_four: '(max-width: 1199px)';
            - $laptop_m_five: '(max-width: 1024px)';
            - $laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
            - $medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
            - $ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
            - $tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
            - $tab_device_two: '(max-width: 768px)';
            - $large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
            - $large_mobile_two: ' (max-width: 600px)';
            - $large_mobile_three: ' (max-width: 480px)';
            - $small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
            - $small_mobile_four: '(max-width: 425px)';
            - $small_mobile_three: '(max-width: 400px)';
            - $small_mobile_five: '(max-width: 375px)';
            - $small_mobile_two: '(max-width: 320px)';
-------------------------------------------------------------------*/
* {
  /* border: 1px solid red */
}
/*============== loader-ripple ==============*/
@keyframes loader-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/*============== play-button-pulse ==============*/
@-webkit-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@-moz-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@-ms-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@-o-keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes play-button-pulse {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

/*== heartBeat ==*/
@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
    transform: scale(1.04);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes cluster-animation {
  0%,
  100% {
    -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  }
  50% {
    -webkit-box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0.1);
  }
}

@keyframes bounce {
  0%,
  20%,
  60%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(-5px);
  }
}

/*============== run ==============*/
@-webkit-keyframes run {
  0% {
    top: -60%;
  }
  100% {
    top: 120%;
  }
}

@-ms-keyframes run {
  0% {
    top: -60%;
  }
  100% {
    top: 120%;
  }
}

@-o-keyframes run {
  0% {
    top: -60%;
  }
  100% {
    top: 120%;
  }
}

@keyframes run {
  0% {
    top: -60%;
  }
  100% {
    top: 120%;
  }
}

/*animated scroll arrow animation*/
@-webkit-keyframes arrow_fade_move_down {
  0% {
    -webkit-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
}

@-moz-keyframes arrow_fade_move_down {
  0% {
    -moz-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 20px);
    opacity: 0;
  }
}

@keyframes arrow_fade_move_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes growIn {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

input:focus,
button:focus,
.dropdown-item:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333f57;
  font-family: "Route 159 Bold";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  opacity: 1;
  transition: 0.8s;
}

img[data-img] {
  opacity: 0;
}

body {
  font-size: 16px;
  line-height: 24px;
  font-family: "Be Vietnam", "sans-serif";
  color: #808996;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-be-vietnam {
  font-family: "Be Vietnam", "sans-serif" !important;
}

.font-Route {
  font-family: "Route 159 Bold" !important;
}

/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: "Route 159 Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Route 159 Regular"), url("../fonts/Route159-Regular.woff") format("woff");
}

@font-face {
  font-family: "Route 159 UltraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Route 159 UltraLight"),
    url("../fonts/Route159-UltraLight.woff") format("woff");
}

@font-face {
  font-family: "Route 159 Light";
  font-style: normal;
  font-weight: normal;
  src: local("Route 159 Light"), url("../fonts/Route159-Light.woff") format("woff");
}

@font-face {
  font-family: "Route 159 SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Route 159 SemiBold"), url("../fonts/Route159-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Route 159 Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Route 159 Bold"), url("../fonts/Route159-Bold.woff") format("woff");
}

@font-face {
  font-family: "Route 159 Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Route 159 Heavy"), url("../fonts/Route159-Heavy.woff") format("woff");
}

/*======= scrollbar-hidden ======*/
.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/*======= spacing ======*/
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section--padding {
  padding-top: 100px;
  padding-bottom: 70px;
}

.section--margin {
  margin-top: 100px;
  margin-bottom: 100px;
}

.section--margin {
  margin-top: 100px;
  margin-bottom: 70px;
}

/* padding top */
.padding-top-320px {
  padding-top: 320px !important;
}

.padding-top-200px {
  padding-top: 200px !important;
}

.padding-top-190px {
  padding-top: 190px !important;
}

.padding-top-180px {
  padding-top: 180px !important;
}

.padding-top-170px {
  padding-top: 170px !important;
}

.padding-top-160px {
  padding-top: 160px !important;
}

.padding-top-150px {
  padding-top: 150px !important;
}

.padding-top-140px {
  padding-top: 140px !important;
}

.padding-top-130px {
  padding-top: 130px !important;
}

.padding-top-120px {
  padding-top: 120px !important;
}

.padding-top-110px {
  padding-top: 110px !important;
}

.padding-top-100px {
  padding-top: 100px !important;
}

.padding-top-95px {
  padding-top: 95px !important;
}

.padding-top-90px {
  padding-top: 90px !important;
}

.padding-top-85px {
  padding-top: 85px !important;
}

.padding-top-80px {
  padding-top: 80px !important;
}

.padding-top-70px {
  padding-top: 70px !important;
}

.padding-top-60px {
  padding-top: 60px !important;
}

.padding-top-50px {
  padding-top: 50px !important;
}

.padding-top-50px {
  padding-top: 50px !important;
}

.padding-top-45px {
  padding-top: 45px !important;
}

.padding-top-40px {
  padding-top: 40px !important;
}

.padding-top-35px {
  padding-top: 35px !important;
}

.padding-top-30px {
  padding-top: 30px !important;
}

.padding-top-25px {
  padding-top: 25px !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.padding-top-10px {
  padding-top: 10px !important;
}

/* padding bottom */
.padding-bottom-200px {
  padding-bottom: 200px !important;
}

.padding-bottom-190px {
  padding-bottom: 190px !important;
}

.padding-bottom-180px {
  padding-bottom: 180px !important;
}

.padding-bottom-170px {
  padding-bottom: 170px !important;
}

.padding-bottom-160px {
  padding-bottom: 160px !important;
}

.padding-bottom-150px {
  padding-bottom: 150px !important;
}

.padding-bottom-140px {
  padding-bottom: 140px !important;
}

.padding-bottom-130px {
  padding-bottom: 130px !important;
}

.padding-bottom-120px {
  padding-bottom: 120px !important;
}

.padding-bottom-110px {
  padding-bottom: 110px !important;
}

.padding-bottom-100px {
  padding-bottom: 100px !important;
}

.padding-bottom-95px {
  padding-bottom: 95px !important;
}

.padding-bottom-90px {
  padding-bottom: 90px !important;
}

.padding-bottom-85px {
  padding-bottom: 85px !important;
}

.padding-bottom-80px {
  padding-bottom: 80px !important;
}

.padding-bottom-70px {
  padding-bottom: 70px !important;
}

.padding-bottom-60px {
  padding-bottom: 60px !important;
}

.padding-bottom-50px {
  padding-bottom: 50px !important;
}

.padding-bottom-45px {
  padding-bottom: 45px !important;
}

.padding-bottom-40px {
  padding-bottom: 40px !important;
}

.padding-bottom-35px {
  padding-bottom: 35px !important;
}

.padding-bottom-30px {
  padding-bottom: 30px !important;
}

.padding-bottom-25px {
  padding-bottom: 25px !important;
}

.padding-bottom-20px {
  padding-bottom: 20px !important;
}

.padding-bottom-10px {
  padding-bottom: 10px !important;
}

/* padding right */
.padding-right-100px {
  padding-right: 100px !important;
}

.padding-right-95px {
  padding-right: 95px !important;
}

.padding-right-90px {
  padding-right: 90px !important;
}

.padding-right-85px {
  padding-right: 85px !important;
}

.padding-right-80px {
  padding-right: 80px !important;
}

.padding-right-70px {
  padding-right: 70px !important;
}

.padding-right-60px {
  padding-right: 60px !important;
}

.padding-right-55px {
  padding-right: 55px !important;
}

.padding-right-50px {
  padding-right: 50px !important;
}

.padding-right-45px {
  padding-right: 45px !important;
}

.padding-right-40px {
  padding-right: 40px !important;
}

.padding-right-30px {
  padding-right: 30px !important;
}

.padding-right-20px {
  padding-right: 20px !important;
}

.padding-right-25px {
  padding-right: 25px !important;
}

.padding-right-15px {
  padding-right: 15px !important;
}

.padding-right-10px {
  padding-right: 10px !important;
}

/* padding left */
.padding-left-100px {
  padding-left: 100px !important;
}

.padding-left-95px {
  padding-left: 95px !important;
}

.padding-left-90px {
  padding-left: 90px !important;
}

.padding-left-85px {
  padding-left: 85px !important;
}

.padding-left-80px {
  padding-left: 80px !important;
}

.padding-left-70px {
  padding-left: 70px !important;
}

.padding-left-60px {
  padding-left: 60px !important;
}

.padding-left-55px {
  padding-left: 55px !important;
}

.padding-left-50px {
  padding-left: 50px !important;
}

.padding-left-45px {
  padding-left: 45px !important;
}

.padding-left-40px {
  padding-left: 40px !important;
}

.padding-left-30px {
  padding-left: 30px !important;
}

.padding-left-25px {
  padding-left: 25px !important;
}

.padding-left-20px {
  padding-left: 20px !important;
}

.padding-left-15px {
  padding-left: 15px !important;
}

.padding-left-10px {
  padding-left: 10px !important;
}

/*===== margin top ====*/
.margin-top-200px {
  margin-top: 200px !important;
}

.margin-top-190px {
  margin-top: 190px !important;
}

.margin-top-180px {
  margin-top: 180px !important;
}

.margin-top-160px {
  margin-top: 160px !important;
}

.margin-top-150px {
  margin-top: 150px !important;
}

.margin-top-140px {
  margin-top: 140px !important;
}

.margin-top-130px {
  margin-top: 130px !important;
}

.margin-top-120px {
  margin-top: 120px !important;
}

.margin-top-110px {
  margin-top: 110px !important;
}

.margin-top-100px {
  margin-top: 100px !important;
}

.margin-top-95px {
  margin-top: 95px !important;
}

.margin-top-90px {
  margin-top: 90px !important;
}

.margin-top-85px {
  margin-top: 85px !important;
}

.margin-top-80px {
  margin-top: 80px !important;
}

.margin-top-70px {
  margin-top: 70px !important;
}

.margin-top-60px {
  margin-top: 60px !important;
}

.margin-top-55px {
  margin-top: 55px !important;
}

.margin-top-50px {
  margin-top: 50px !important;
}

.margin-top-45px {
  margin-top: 45px !important;
}

.margin-top-40px {
  margin-top: 40px !important;
}

.margin-top-35px {
  margin-top: 35px !important;
}

.margin-top-30px {
  margin-top: 30px !important;
}

.margin-top-25px {
  margin-top: 25px !important;
}

.margin-top-20px {
  margin-top: 20px !important;
}

.margin-top-10px {
  margin-top: 10px !important;
}

/*===== margin bottom ====*/
.margin-bottom-200px {
  margin-bottom: 200px !important;
}

.margin-bottom-190px {
  margin-bottom: 190px !important;
}

.margin-bottom-180px {
  margin-bottom: 180px !important;
}

.margin-bottom-170px {
  margin-bottom: 170px !important;
}

.margin-bottom-160px {
  margin-bottom: 160px !important;
}

.margin-bottom-150px {
  margin-bottom: 150px !important;
}

.margin-bottom-140px {
  margin-bottom: 140px !important;
}

.margin-bottom-130px {
  margin-bottom: 130px !important;
}

.margin-bottom-120px {
  margin-bottom: 120px !important;
}

.margin-bottom-110px {
  margin-bottom: 110px !important;
}

.margin-bottom-100px {
  margin-bottom: 100px !important;
}

.margin-bottom-95px {
  margin-bottom: 95px !important;
}

.margin-bottom-90px {
  margin-bottom: 90px !important;
}

.margin-bottom-85px {
  margin-bottom: 85px !important;
}

.margin-bottom-80px {
  margin-bottom: 80px !important;
}

.margin-bottom-70px {
  margin-bottom: 70px !important;
}

.margin-bottom-60px {
  margin-bottom: 60px !important;
}

.margin-bottom-50px {
  margin-bottom: 50px !important;
}

.margin-bottom-55px {
  margin-bottom: 55px !important;
}

.margin-bottom-45px {
  margin-bottom: 45px !important;
}

.margin-bottom-40px {
  margin-bottom: 40px !important;
}

.margin-bottom-35px {
  margin-bottom: 35px !important;
}

.margin-bottom-30px {
  margin-bottom: 30px !important;
}

.margin-bottom-25px {
  margin-bottom: 25px !important;
}

.margin-bottom-20px {
  margin-bottom: 20px !important;
}

.margin-bottom-10px {
  margin-bottom: 10px !important;
}

/*---------------- bg ----------------*/
.bg-white {
  background-color: #fff !important;
}

.bg-dark {
  background-color: #333f57 !important;
}

.bg-gray {
  background-color: #f5f7fc !important;
}

.bg-gradient-gray {
  background: linear-gradient(70deg, #e8eef9 0, #fff 100%) !important;
}

.bg-transparent {
  background-image: none !important;
}

.bg-dark-opacity {
  background-color: rgba(11, 11, 11, 0.1) !important;
}

.bg-fixed {
  background-attachment: fixed !important;
}

/*===== gradient bg ======*/
.bg-gradient-1 {
  background-image: linear-gradient(45deg, #5065cd 0%, #c366bd 100%) !important;
}

.bg-gradient-2 {
  background-image: linear-gradient(62deg, #d6875a 0%, #f7ce68 100%) !important;
}

.bg-gradient-3 {
  background-image: linear-gradient(45deg, #0877b9 0%, #80d0c7 100%) !important;
}

.bg-gradient-4 {
  background-image: linear-gradient(45deg, #31d0f5 0%, #2c61e6 100%) !important;
}

.bg-1 {
  background-color: #8c43ff !important;
}

.bg-2 {
  background-color: #28d5a7 !important;
}

.bg-3 {
  background-color: #f9b851 !important;
}

.bg-4 {
  background-color: #cc08e9 !important;
}

.bg-5 {
  background-color: #ff8c2a !important;
}

.bg-6 {
  background-color: #25b15f !important;
}

.bg-7 {
  background-color: #307cf3 !important;
}

.bg-8 {
  background-color: #4267b2 !important;
}

.bg-9 {
  background-color: #71c9f8 !important;
}

.bg-10 {
  background-color: #7b68ee !important;
}

.bg-opacity-1 {
  background: rgba(140, 67, 255, 0.1) !important;
}

.bg-opacity-2 {
  background: rgba(40, 213, 167, 0.1) !important;
}

.bg-opacity-3 {
  background: rgba(249, 184, 81, 0.1) !important;
}

.bg-opacity-4 {
  background: rgba(204, 8, 233, 0.1) !important;
}

.f-bg {
  background-color: #4267b2 !important;
}

.t-bg {
  background-color: #71c9f8 !important;
}

.i-bg {
  background-color: #c13584 !important;
}

.y-bg {
  background-color: #e62117 !important;
}

.f-bg-rgb {
  background-color: rgba(66, 103, 178, 0.1) !important;
  color: #4267b2 !important;
}

.t-bg-rgb {
  background-color: rgba(113, 201, 248, 0.1) !important;
  color: #71c9f8 !important;
}

.i-bg-rgb {
  background-color: rgba(193, 53, 172, 0.1) !important;
  color: #c13584 !important;
}

.y-bg-rgb {
  background-color: rgba(230, 33, 23, 0.1) !important;
  color: #e62117 !important;
}

.bg-rgb-danger {
  background-color: rgba(220, 53, 69, 0.1) !important;
  color: #dc3545 !important;
}
.bg-rgb-danger:hover {
  background-color: rgba(220, 53, 69, 0.2) !important;
}

.bg-rgb-success {
  background-color: rgba(40, 167, 69, 0.1) !important;
  color: #28a745 !important;
}
.bg-rgb-success:hover {
  background-color: rgba(40, 167, 69, 0.2) !important;
}

.svg-icon-color path {
  fill: #8c43ff;
}

.svg-icon-color-2 path {
  fill: #28d5a7;
}

.svg-icon-color-3 path {
  fill: #f9b851;
}

.svg-icon-color-4 path {
  fill: #cc08e9;
}

.svg-icon-white path {
  fill: #fff;
}

/*---------------- font sizes ----------------*/
.font-size-100 {
  font-size: 100px !important;
}

.font-size-95 {
  font-size: 95px !important;
}

.font-size-90 {
  font-size: 90px !important;
}

.font-size-85 {
  font-size: 85px !important;
}

.font-size-80 {
  font-size: 80px !important;
}

.font-size-75 {
  font-size: 75px !important;
}

.font-size-70 {
  font-size: 70px !important;
}

.font-size-65 {
  font-size: 65px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.font-size-55 {
  font-size: 55px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-27 {
  font-size: 27px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-23 {
  font-size: 23px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

/*---------------- line-height ----------------*/
.line-height-100 {
  line-height: 100px !important;
}

.line-height-95 {
  line-height: 95px !important;
}

.line-height-90 {
  line-height: 90px !important;
}

.line-height-85 {
  line-height: 85px !important;
}

.line-height-80 {
  line-height: 80px !important;
}

.line-height-75 {
  line-height: 75px !important;
}

.line-height-70 {
  line-height: 70px !important;
}

.line-height-65 {
  line-height: 65px !important;
}

.line-height-60 {
  line-height: 60px !important;
}

.line-height-55 {
  line-height: 55px !important;
}

.line-height-50 {
  line-height: 50px !important;
}

.line-height-45 {
  line-height: 45px !important;
}

.line-height-40 {
  line-height: 40px !important;
}

.line-height-35 {
  line-height: 35px !important;
}

.line-height-30 {
  line-height: 30px !important;
}

.line-height-28 {
  line-height: 28px !important;
}

.line-height-27 {
  line-height: 27px !important;
}

.line-height-26 {
  line-height: 26px !important;
}

.line-height-25 {
  line-height: 25px !important;
}

.line-height-24 {
  line-height: 24px !important;
}

.line-height-23 {
  line-height: 23px !important;
}

.line-height-22 {
  line-height: 22px !important;
}

.line-height-21 {
  line-height: 21px !important;
}

.line-height-20 {
  line-height: 20px !important;
}

.line-height-18 {
  line-height: 18px !important;
}

.line-height-16 {
  line-height: 16px !important;
}

.line-height-15 {
  line-height: 15px !important;
}

/*---------------- font-weight ----------------*/
.font-weight-thin {
  font-weight: 200 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

/*=== opacity ====*/
.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

/*=== radius ====*/
.radius-round {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}

.radius-rounded {
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
}

/*=== before after ====*/
.ba-none:before,
.ba-none:after {
  display: none !important;
}

.before-none:before {
  display: none !important;
}

.after-none:after {
  display: none !important;
}

/*=== section-block ====*/
.section-block,
.section-block-2 {
  height: 1px;
}

.section-block {
  background-color: #f5f7fc;
}

.section-block-2 {
  background-color: rgba(127, 137, 150, 0.1);
}

/*=== border color ====*/
.border-color {
  border-color: rgba(127, 137, 150, 0.1) !important;
}

.border-top-color {
  border-top-color: rgba(127, 137, 150, 0.1) !important;
}

.border-right-color {
  border-right-color: rgba(127, 137, 150, 0.1) !important;
}

.border-bottom-color {
  border-bottom-color: rgba(127, 137, 150, 0.1) !important;
}

.border-left-color {
  border-left-color: rgba(127, 137, 150, 0.1) !important;
}

/*=== text color ====*/
.text-gray {
  color: #808996 !important;
}

.text-color {
  color: #333f57 !important;
}

.text-color-2 {
  color: #7b68ee !important;
}

.text-color-3 {
  color: #8c43ff !important;
}

.text-color-4 {
  color: #28d5a7 !important;
}

.text-color-5 {
  color: #f9b851 !important;
}

.text-color-6 {
  color: #cc08e9 !important;
}

.text-color-7 {
  color: #ff8c2a !important;
}

.text-color-8 {
  color: #25b15f !important;
}

.text-color-9 {
  color: #307cf3 !important;
}

.text-color-10 {
  color: #4267b2 !important;
}

.text-color-11 {
  color: #71c9f8 !important;
}

.text-color-12 {
  color: #c13584 !important;
}

.text-color-13 {
  color: #ea4c89 !important;
}

.text-color-14 {
  color: #053eff !important;
}

.text-color-15 {
  color: #db4437 !important;
}

.text-color-16 {
  color: #ffbb3d !important;
}

/*=== p-relative p-absolute ====*/
.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.position-inherit {
  position: inherit !important;
}

.top-auto {
  top: auto !important;
}

.right-auto {
  right: auto !important;
}

.bottom-auto {
  bottom: auto !important;
}

.left-auto {
  left: auto !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.bounce {
  animation: bounce 8s ease-in-out infinite;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-negative {
  z-index: -1;
}

.flex-column-inherit {
  flex-direction: inherit !important;
}

/*---------------- container ----------------*/
.container {
  max-width: 1200px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-text-center {
    text-align: center !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .responsive-text-center {
    text-align: center !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .responsive-text-center {
    text-align: center !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-text-left {
    text-align: left !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .responsive-text-left {
    text-align: left !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .responsive-text-left {
    text-align: left !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-text-right {
    text-align: right !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .responsive-text-right {
    text-align: right !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .responsive-text-right {
    text-align: right !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-column {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .responsive-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .responsive-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1366px) {
  .responsive-column-lg {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .responsive-column-lg {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .responsive-column-lg {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .responsive-column-lg {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .responsive-column--lg {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*=== theme-btn ====*/
.theme-btn {
  font-weight: 600;
  font-size: 16px;
  color: #333f57;
  line-height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #f5f7fc;
  text-align: center;
  position: relative;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}
.theme-btn:hover {
  color: #fff;
}

.theme-btn-primary {
  font-weight: 500;
  background-color: #6777ef;
  border-color: #6777ef;
  color: #fff;
}
.theme-btn-primary:hover {
  background-color: #4c60da;
  border-color: #4c60da;
  color: #fff;
}

.btn-text {
  color: #808996;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
}
.btn-text .icon {
  position: absolute;
  right: -20px;
  top: 6px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-text:hover {
  color: #7b68ee;
}
.btn-text:hover .icon {
  right: -25px;
}

.btn-transparent {
  background-color: rgba(40, 213, 167, 0.1);
  color: #28d5a7;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-transparent:hover {
  background-color: #28d5a7;
  color: #fff;
}

.gradient-btn {
  background: linear-gradient(270deg, #9f8fff, #7b68ee);
  position: relative;
  color: #fff;
  -webkit-box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  -moz-box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  border: none;
}
.gradient-btn::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background: linear-gradient(270deg, #7b68ee, #9f8fff);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: -1;
  border: 1px solid #7b68ee;
}
.gradient-btn:hover::before {
  opacity: 1;
  visibility: visible;
}

.offer-btn {
  background: #fff;
  color: #7b68ee;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: 800;
  padding: 8px 30px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
@media (max-width: 320px) {
  .offer-btn {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 21px;
  }
}

/*==== collapse-btn =====*/
.collapse-btn {
  font-weight: 500;
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.collapse-btn[aria-expanded="true"] .collapse-btn-show {
  display: inline-block;
}
.collapse-btn[aria-expanded="true"] .collapse-btn-hide {
  display: none;
}
.collapse-btn[aria-expanded="false"] .collapse-btn-show {
  display: none;
}
.collapse-btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.collapse-btn:hover {
  color: #333f57;
}

/*====== btn-gray =======*/
.btn-gray {
  background-color: #fff;
  color: #808996;
  padding: 7px 14px;
  font-size: 16px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(128, 137, 150, 0.2);
  font-weight: 500;
}
.btn-gray:hover {
  color: #333f57;
}
.btn-gray .active {
  background-color: #40cc6f;
  color: #fff;
  border: #40cc6f;
}

.btn-gray-lg {
  padding-top: 12px;
  padding-bottom: 12px;
}

.btn-gray-sm {
  padding: 5px 10px;
  font-size: 15px;
}

.open-filter-btn.active {
  background-color: #40cc6f;
  color: #fff;
  border-color: #40cc6f;
}
.open-filter-btn.active .la-clock::before {
  content: "\f00c";
}

/*======== dot-action-btn ========*/
.dot-action-btn {
  background-color: transparent;
  font-size: 25px;
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.dot-action-btn:hover {
  color: #333f57;
}

.button-warning {
  background-color: #f9b851 !important;
}

.button-danger {
  background-color: #7b68ee !important;
}

.fixed-btn {
  position: fixed;
  top: 30%;
  right: -70px;
  z-index: 1024;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: 0 10px 20px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 20px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 20px rgba(82, 85, 90, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
}
.fixed-btn:hover {
  right: -1px;
}

/*=== section-icon ====*/
.section-icon {
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: linear-gradient(
    to right,
    rgba(255, 201, 196, 0.15),
    rgba(255, 222, 162, 0.15)
  );
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.section-icon svg {
  width: 40px;
}

.gradient-icon svg path {
  fill: url(#svg-gradient);
}

.gradient-bg svg path {
  fill: url(#svg-gradient-3);
}

.gradient-bg-2 svg path {
  fill: url(#svg-gradient-2);
}

.gradient-bg-3 svg path {
  fill: url(#svg-gradient-4);
}

.gradient-text {
  background-image: -webkit-linear-gradient(to right, #ff6c5f, #ffbb3d);
  background-image: linear-gradient(to right, #ff6c5f, #ffbb3d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*==== svg-bg ====*/
.svg-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.svg-bg svg {
  width: calc(100% + 1.6px);
  height: 55px;
}

/*==== svg-bg-2 ====*/
.svg-bg-2 svg {
  width: 190%;
  height: auto;
}

/*==== svg-bg-3 ====*/
.svg-bg-3 svg {
  width: 100%;
  height: 350px;
}

/*==== svg-bg-4 ====*/
.svg-bg-4 svg {
  width: 100%;
  height: 450px;
}

/*==== circle-bg ====*/
.circle-bg {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 3px solid #ddd;
  border-radius: 50%;
}

.circle-bg-1 {
  top: 10%;
  left: 10%;
}

.circle-bg-2 {
  top: 30%;
  left: 25%;
}

.circle-bg-3 {
  bottom: 25%;
  left: 13%;
}

.circle-bg-4 {
  top: 10%;
  right: 20%;
}

.circle-bg-5 {
  bottom: 40%;
  right: 10%;
  width: 40px;
  height: 40px;
}

/*========= preloader ==========*/
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 99999;
}
.loader-container .loader-ripple {
  position: relative;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader-container .loader-ripple div {
  position: absolute;
  border: 4px solid #7b68ee;
  opacity: 1;
  border-radius: 50%;
  /* animation: loader-ripple 3s cubic-bezier(0, 0.2, 0.8, 1) infinite; */
  animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader-container .loader-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

/*========= ribbon =========*/
.ribbon {
  display: inline-block;
  padding: 0 6px;
  background: rgba(255, 187, 61, 0.1);
  color: #ffbb3d;
  font-size: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  line-height: 20px;
  font-weight: 600;
}

.ribbon-2 {
  padding: 5px 18px;
  font-size: 15px;
  background-color: #fff;
  color: #333f57;
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.ribbon-lg {
  font-size: 16px;
  padding: 5px 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/* scroll-down-arrow */
.scroll-down-arrow {
  cursor: pointer;
  font-size: 22px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: 50px;
  color: #fff;
  text-align: center;
  z-index: 100;
  -webkit-animation: arrow_fade_move_down 2s ease-in-out infinite;
  -moz-animation: arrow_fade_move_down 2s ease-in-out infinite;
  animation: arrow_fade_move_down 2s ease-in-out infinite;
}

.arrow-down-shape {
  position: relative;
}
.arrow-down-shape:after {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  background-color: #f5f7fc;
  bottom: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.hover-scale:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.hover-scale-2:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
  transform: scale(1.02);
}

.hover-y:hover {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333f57;
  opacity: 0.8;
  pointer-events: none;
}
.overlay2 {
  /* position: absolute; */
  /* content: ""; */
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 24%;
  background-color: #333f57;
  opacity: 0.8;
  /* pointer-events: none; */
}
@media screen and (max-width: 500px) {
  .overlay2 {
    height: 37% !important;
  }
}

#fullscreen-slide-container {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: -2;
}

/* pattern-bg */
.pattern-bg,
.pattern-bg-2 {
  position: relative;
  z-index: 1;
}

.pattern-bg:after,
.pattern-bg-2:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: -1;
}

.pattern-bg:after {
  background-image: url("../images/pattern.png");
}

.pattern-bg-2:after {
  background-image: url("../images/pattern2.png");
  background-size: inherit;
}

/*====================================================
    section-heading
 ====================================================*/
.section-heading .sec__title {
  font-size: 36px;
  line-height: 50px;
  color: #333f57;
  margin-bottom: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-heading .sec__title br {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .section-heading .sec__title br {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .section-heading .sec__title br {
    display: none;
  }
}
@media (max-width: 480px) {
  .section-heading .sec__title {
    font-size: 32px;
    line-height: 40px;
  }
}

.section-heading .sec__desc {
  font-size: 18px;
  color: #808996;
  font-weight: 500;
  line-height: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-heading .sec__desc br {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .section-heading .sec__desc br {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .section-heading .sec__desc br {
    display: none;
  }
}

.sec__list li {
  display: inline-block;
  margin-left: 4px;
}
.sec__list li:first-child {
  margin-left: 0;
}
.sec__list li a {
  display: block;
  color: #533ae9;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 2px solid rgba(140, 67, 255, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.sec__list li a:hover {
  background-color: #533ae9;
  color: #fff;
  border-color: #533ae9;
}

/*====================================================
    custom-checkbox
 ====================================================*/
.custom-checkbox {
  display: block;
  margin-bottom: 12px;
}
@media (max-width: 400px) {
  .custom-checkbox {
    margin-right: 0;
    display: block;
  }
}
.custom-checkbox label {
  color: #808996;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 0;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom-checkbox label:hover {
  color: #333f57;
}
.custom-checkbox label .cat-badge {
  float: right;
  background-color: rgba(128, 137, 150, 0.1);
  display: block;
  padding: 2px 4px;
  line-height: 20px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
}
.custom-checkbox input[type="checkbox"]:not(:checked),
.custom-checkbox input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.custom-checkbox input[type="checkbox"]:not(:checked) + label,
.custom-checkbox input[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 26px;
}
.custom-checkbox input[type="checkbox"]:not(:checked) + label:before,
.custom-checkbox input[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  background-color: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.custom-checkbox input[type="checkbox"]:not(:checked) + label:after,
.custom-checkbox input[type="checkbox"]:checked + label:after {
  content: "\f00c";
  position: absolute;
  top: 1px;
  left: 3px;
  font-size: 12px;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-family: "Line Awesome Free";
  font-weight: 900;
}
.custom-checkbox input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  visibility: hidden;
}
.custom-checkbox input[type="checkbox"]:checked + label {
  color: #333f57;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
  visibility: visible;
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: #7b68ee;
  border-color: #7b68ee;
}

/*== custom-radio ==*/
.custom-radio li + li {
  padding-top: 10px;
}

.custom-radio li .la {
  color: #f9b851;
}

.custom-radio .radio-label {
  position: relative;
  user-select: none;
  display: inline-block;
  margin-right: 28px;
}
.custom-radio .radio-label input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.custom-radio .radio-label input:checked ~ .radio-mark {
  border-color: #f9b851;
}
.custom-radio .radio-label input:checked ~ .radio-mark:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-radio .radio-label .radio-mark {
  position: absolute;
  top: -3px;
  left: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: #e4e4e4;
  border-image: initial;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.custom-radio .radio-label .radio-mark:before {
  top: 2px;
  left: 2px;
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #f9b851;
  opacity: 0;
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -o-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/*================= Chosen select ====================*/
.user-chosen-select-container .chosen-container {
  color: #808996;
  font-size: 15px;
  width: 100% !important;
  font-weight: 500;
}

.user-chosen-select-container .chosen-drop {
  border-color: rgba(128, 137, 150, 0.3);
  -webkit-box-shadow: 0 10px 15px 0 rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 10px 15px 0 rgba(82, 85, 90, 0.2);
  box-shadow: 0 10px 15px 0 rgba(82, 85, 90, 0.2);
  margin-top: -2px;
  padding-right: 15px;
}

.user-chosen-select-container .chosen-container-active.chosen-with-drop .chosen-single {
  background: #fff;
  border-color: rgba(128, 137, 150, 0.3);
}

.user-chosen-select-container .chosen-single span {
  color: #808996;
}

.user-chosen-select-container .chosen-single div {
  top: 13px;
  right: 5px;
}

.user-chosen-select-container .chosen-single abbr {
  top: 19px;
  right: 30px;
}

.user-chosen-select-container .chosen-single,
.user-chosen-select-container .chosen-choices {
  padding: 12px 20px;
  height: auto;
  color: #808996;
  border-color: rgba(128, 137, 150, 0.3);
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.user-chosen-select-container .chosen-choices {
  padding: 10px 15px;
}
.user-chosen-select-container .chosen-choices li.search-choice {
  border-color: rgba(128, 137, 150, 0.3);
}

.user-chosen-select-container .chosen-search {
  padding: 10px 0 5px 15px;
}
.user-chosen-select-container .chosen-search input[type="text"] {
  border-color: rgba(128, 137, 150, 0.3);
  padding: 6px 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #333f57;
}

.user-chosen-select-container .chosen-results {
  margin-right: 0;
  padding-left: 0;
  max-height: 210px;
}
.user-chosen-select-container .chosen-results::-webkit-scrollbar {
  width: 8px;
}
.user-chosen-select-container .chosen-results::-webkit-scrollbar-track {
  background-color: rgba(128, 137, 150, 0.1);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.user-chosen-select-container .chosen-results::-webkit-scrollbar-thumb {
  background-color: rgba(128, 137, 150, 0.2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.user-chosen-select-container .chosen-results::-webkit-scrollbar-thumb:hover {
  background-color: rgba(128, 137, 150, 0.4);
}
.user-chosen-select-container .chosen-results li {
  padding-right: 15px;
  padding-left: 15px;
  line-height: inherit;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.user-chosen-select-container .chosen-results li.highlighted {
  background: rgba(44, 44, 81, 0.1);
  color: #333f57;
}

/* chosen-container */
.chosen-container .chosen-drop {
  left: 0;
  display: none;
}

.chosen-container.chosen-drop-up .chosen-drop {
  top: auto;
  bottom: 100%;
  border-top: 1px rgba(128, 137, 150, 0.3);
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: 0 -10px 15px 0 rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 -10px 15px 0 rgba(82, 85, 90, 0.2);
  box-shadow: 0 -10px 15px 0 rgba(82, 85, 90, 0.2);
  margin-bottom: -2px;
  margin-top: 0;
  border-bottom: 0;
}

.chosen-container.chosen-drop-up .chosen-single {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.chosen-container.chosen-with-drop .chosen-drop {
  display: block;
}

/* range-slider */
.range-slider.ui-widget-content {
  border: none;
  background-color: #eee;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  height: 6px;
  margin-left: 10px;
  margin-right: 10px;
}
.range-slider.ui-widget-content .ui-slider-range {
  background-color: #7b68ee;
}
.range-slider.ui-widget-content .ui-slider-handle {
  background-color: #7b68ee;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: 7px solid #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  height: 22px;
  width: 22px;
  top: -8px;
  cursor: ew-resize;
  outline: 0;
}

/* range-slider-price */
.range-slider-price {
  padding-top: 24px;
  padding-left: 7px;
  font-weight: 600;
}
.range-slider-price .filter__label {
  color: #333f57;
  margin: 0;
  font-size: 18px;
}
.range-slider-price .amounts {
  border: none;
  font-weight: 600;
  color: #808996;
}
@media (max-width: 320px) {
  .range-slider-price .amounts {
    width: 50%;
  }
}

/* datedropper */
div.datedropper {
  font-family: "Be Vietnam", sans-serif;
}
div.datedropper .picker ul.pick .pick-arw {
  font-size: 12px;
}
div.datedropper .picker ul.pick:hover .pick-arw {
  opacity: 1;
}
div.datedropper .picker .pick-lg-b li {
  color: #808996;
}

/*======= time-list ========*/
.time-list thead th {
  border-top: 0;
  padding-top: 0;
  vertical-align: middle;
  border-bottom: 0;
  font-weight: 600;
}

.time-list td,
.time-list th {
  vertical-align: middle;
}

.time-list td {
  border-top-color: rgba(128, 137, 150, 0.1);
}

.time-list tbody .business-day {
  color: #808996;
  font-weight: 500;
}

/*======= social-profile ========*/
.social-profile li {
  display: inline-block;
}
.social-profile li a {
  display: block;
  color: #333f57;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: rgba(128, 137, 150, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px;
}
.social-profile li a:hover {
  background-color: #7b68ee;
  color: #fff;
}

/*======= social-profile-colored ========*/
.social-profile-colored li .facebook-bg {
  color: #4267b2;
  background-color: rgba(66, 103, 178, 0.1);
}
.social-profile-colored li .facebook-bg:hover {
  background-color: #4267b2;
}

.social-profile-colored li .twitter-bg {
  color: #71c9f8;
  background-color: rgba(29, 161, 242, 0.1);
}
.social-profile-colored li .twitter-bg:hover {
  background-color: #71c9f8;
}

.social-profile-colored li .instagram-bg {
  color: #c13584;
  background-color: rgba(193, 53, 132, 0.1);
}
.social-profile-colored li .instagram-bg:hover {
  background-color: #c13584;
}

.social-profile-colored li .dribbble-bg {
  color: #ea4c89;
  background-color: rgba(234, 76, 137, 0.1);
}
.social-profile-colored li .dribbble-bg:hover {
  background-color: #ea4c89;
}

.social-profile-colored li .behance-bg {
  color: #053eff;
  background-color: rgba(5, 62, 255, 0.1);
}
.social-profile-colored li .behance-bg:hover {
  background-color: #053eff;
}

.social-profile-colored li .google-bg {
  color: #db4437;
  background-color: rgba(219, 68, 55, 0.1);
}
.social-profile-colored li .google-bg:hover {
  background-color: #db4437;
}

.social-profile-colored li .youtube-bg {
  color: #e62117;
  background-color: rgba(230, 33, 23, 0.1);
}
.social-profile-colored li .youtube-bg:hover {
  background-color: #e62117;
}

/*======= social-profile-colored-2========*/
.social-profile-colored-2 li a {
  color: #fff;
}

.social-profile-colored-2 li .facebook-bg {
  background-color: #4267b2;
}
.social-profile-colored-2 li .facebook-bg:hover {
  background-color: #4267b2;
}

.social-profile-colored-2 li .twitter-bg {
  background-color: #71c9f8;
}
.social-profile-colored-2 li .twitter-bg:hover {
  background-color: #71c9f8;
}

.social-profile-colored-2 li .instagram-bg {
  background-color: #c13584;
}
.social-profile-colored-2 li .instagram-bg:hover {
  background-color: #c13584;
}

.social-profile-colored-2 li .dribbble-bg {
  background-color: #ea4c89;
}
.social-profile-colored-2 li .dribbble-bg:hover {
  background-color: #ea4c89;
}

.social-profile-colored-2 li .behance-bg {
  background-color: #053eff;
}
.social-profile-colored-2 li .behance-bg:hover {
  background-color: #053eff;
}

.social-profile-colored-2 li .google-bg {
  background-color: #db4437;
}
.social-profile-colored-2 li .google-bg:hover {
  background-color: #db4437;
}

.social-profile-colored-2 li .youtube-bg {
  background-color: #e62117;
}
.social-profile-colored-2 li .youtube-bg:hover {
  background-color: #e62117;
}

/*======= social-profile-styled ========*/
.social-profile-styled {
  display: inline-block;
}
.social-profile-styled li {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.social-profile-styled li:nth-child(n + 2) {
  margin-left: -14px;
}
.social-profile-styled li a {
  background-color: #fff;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.social-profile-styled li .facebook-bg {
  color: #4267b2;
}
.social-profile-styled li .facebook-bg:hover {
  background-color: #4267b2;
  color: #fff;
}
.social-profile-styled li .twitter-bg {
  color: #71c9f8;
}
.social-profile-styled li .twitter-bg:hover {
  background-color: #71c9f8;
  color: #fff;
}
.social-profile-styled li .instagram-bg {
  color: #c13584;
}
.social-profile-styled li .instagram-bg:hover {
  background-color: #c13584;
  color: #fff;
}
.social-profile-styled li .behance-bg {
  color: #053eff;
}
.social-profile-styled li .behance-bg:hover {
  background-color: #053eff;
  color: #fff;
}
.social-profile-styled li .dribbble-bg {
  color: #ea4c89;
}
.social-profile-styled li .dribbble-bg:hover {
  background-color: #ea4c89;
  color: #fff;
}
.social-profile-styled li .youtube-bg {
  color: #e62117;
}
.social-profile-styled li .youtube-bg:hover {
  background-color: #e62117;
  color: #fff;
}
.social-profile-styled:hover li:nth-child(n + 2) {
  margin-left: 0;
}

/*======= social-profile--styled ========*/
.social-profile--styled li:nth-child(n + 2) {
  margin-left: 0;
}

/*======= info-list ========*/
.info-list li {
  display: block;
  font-size: 16px;
  color: #808996;
  font-weight: 500;
  margin-bottom: 6px;
}
.info-list li a {
  color: #808996;
}
.info-list li .icon {
  color: #7b68ee;
  margin-right: 2px;
  font-size: 16px;
  width: 30px;
  height: 30px;
  background-color: rgba(123, 104, 238, 0.1);
  line-height: 30px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.info-list li:hover a {
  color: #7b68ee;
}
.info-list li:hover .icon {
  background-color: #7b68ee;
  color: #fff;
}

/*======== img-boxes =========*/
.img-boxes img {
  width: 100%;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .img-boxes .img-box-item.mt-4 {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .img-boxes .img-box-item.mt-4 {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .img-boxes .img-box-item {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .img-boxes .img-box-item {
    margin-bottom: 20px;
  }
}

.mobile-img img {
  width: 100%;
}

/*======= list-items ========*/
.list-items li {
  margin-bottom: 7px;
  font-weight: 500;
  color: #808996;
  font-size: 16px;
}
.list-items li a {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
}
.list-items li a:before {
  position: absolute;
  content: "";
  bottom: -2px;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #7b68ee;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.list-items li a:hover {
  color: #7b68ee;
}
.list-items li a:hover:before {
  right: auto;
  left: 0;
  width: 100%;
}

/*======== list--items =========*/
.list--items li span {
  width: 130px;
  display: inline-block;
}

/*======== list--items-2 =========*/
.list--items-2 li span {
  width: 100px;
}

/*======== list-items-style =========*/
.list-items-style li {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 15px;
}

/*======== list-items-style-2 =========*/
.list-items-style-2 li {
  margin-bottom: 0;
}
.list-items-style-2 li + li {
  border-top: 1px solid rgba(128, 137, 150, 0.1);
  padding-top: 15px;
  margin-top: 15px;
}
.list-items-style-2 li a:before {
  display: none;
}

/*======== list-items-bullet =========*/
.list-items-bullet li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
.list-items-bullet li:after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(128, 137, 150, 0.6);
  top: 10px;
  left: 0;
}

/*======== quantity-item =========*/
.quantity-item .qtyInput {
  width: 40px;
  border: 0;
  text-align: center;
  color: #333f57;
  font-weight: 500;
  pointer-events: none;
  font-size: 17px;
}

.qtyDec,
.qtyInc {
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 17px;
  background-color: rgba(128, 137, 150, 0.1);
  color: #333f57;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.qtyDec:hover,
.qtyInc:hover {
  background-color: rgba(128, 137, 150, 0.3);
}

.tag-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(128, 137, 150, 0.1);
  font-size: 14px;
}

/*======= tip ======*/
.tip {
  display: inline-block;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #808996;
  font-size: 15px;
  line-height: 18px;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: #fff;
  border: 1px solid rgba(128, 137, 150, 0.3);
}

/*===== jqte =====*/
.jqte {
  margin: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: rgba(128, 137, 150, 0.3);
}
.jqte * {
  font-family: "Be Vietnam", sans-serif !important;
}
.jqte ul,
.jqte dl,
.jqte ol {
  padding: 5px 5px 5px 16px;
}
.jqte ul {
  list-style: disc;
}

.jqte_toolbar {
  background-color: rgba(128, 137, 150, 0.1);
  border-bottom-color: rgba(128, 137, 150, 0.3);
}

.jqte_tool {
  padding: 3px;
}
.jqte_tool:hover {
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
}

.jqte_tool,
.jqte_tool_icon,
.jqte_tool_label {
  border-color: transparent !important;
}

.jqte_title {
  font-weight: 600;
}

.jqte_tool.jqte_tool_1 .jqte_tool_label {
  width: 80px;
  height: 24px;
  padding: 0;
}

.jqte_formats,
.jqte_fontsizes,
.jqte_cpalette,
.jqte_linktypes {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  border-color: rgba(128, 137, 150, 0.3);
}

.jqte_formats .jqte_format:hover,
.jqte_fontsizes .jqte_fontsize:hover,
.jqte_linktypes a:hover {
  background-color: rgba(128, 137, 150, 0.1);
}

.jqte_editor,
.jqte_source {
  min-height: 160px;
}

.jqte_placeholder {
  display: block;
}

.jqte_placeholder_text {
  top: 49px;
  color: #808996;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .jqte_placeholder_text {
    top: 80px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .jqte_placeholder_text {
    top: 80px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .jqte_placeholder_text {
    top: 130px;
  }
}

.jqte_linktypeview {
  border-color: rgba(128, 137, 150, 0.4);
  padding: 4px 10px;
}

.jqte_linktypearrow {
  bottom: 13px;
  right: 13px;
}

.jqte_linkinput {
  border-color: rgba(128, 137, 150, 0.4);
  padding-top: 4px;
  padding-bottom: 4px;
  color: #333f57;
}
.jqte_linkinput:focus,
.jqte_linkinput:hover {
  border-color: rgba(128, 137, 150, 0.8);
}

.jqte_linkbutton {
  background-color: rgba(128, 137, 150, 0.1);
  border-color: rgba(128, 137, 150, 0.4);
  color: #333f57;
  -webkit-box-shadow: inset 0 0;
  -moz-box-shadow: inset 0 0;
  box-shadow: inset 0 0;
  padding-top: 4px;
  padding-bottom: 4px;
}
.jqte_linkbutton:hover {
  background-color: rgba(128, 137, 150, 0.3);
}

/*========= section-pagination ===========*/
.section-pagination {
  background-color: #fff;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  padding: 12px;
}
.section-pagination .page-item:first-child .page-link {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.section-pagination .page-link {
  border: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-right: 2px;
  margin-left: 2px;
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #fff;
  padding: 7px 13px;
  font-weight: 600;
}
.section-pagination .page-link:hover {
  color: #333f57;
  background-color: rgba(128, 137, 150, 0.1);
}
.section-pagination .page-link.page-link-active {
  background-color: #7b68ee;
  color: #fff;
  border-color: #7b68ee;
}

/*======== pagination-simple =========*/
.pagination-simple .pagination-simple-nav {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
}
.pagination-simple .pagination-simple-nav.active {
  color: #333f57;
}

/*======== filter-bar =========*/
.filter-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(128, 137, 150, 0.1);
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 20px 30px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .filter-bar {
    display: block;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .filter-bar {
    display: block;
  }
}

/*=== filter-bar-action ====*/
.filter-bar-action .user-chosen-select-container .chosen-container {
  width: 147px !important;
}

.filter-bar-action .user-chosen-select-container .chosen-single {
  padding: 3px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  border: 0;
}
.filter-bar-action .user-chosen-select-container .chosen-single div {
  top: 5px;
  right: 5px;
}

.filter-bar-action .user-chosen-select-container .chosen-drop {
  width: 180px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-top: 15px;
  border-top: 1px solid rgba(128, 137, 150, 0.3);
}

/*=== search-filter ====*/
.search-filter {
  color: #808996;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 15px;
}
.search-filter:hover {
  color: #333f57;
}

/*=== filter-nav ====*/
.filter-nav li {
  display: inline-block;
}
.filter-nav li + li {
  margin-left: 3px;
}
.filter-nav li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 31px;
  font-size: 20px;
  color: #808996;
  text-align: center;
  background-color: #fff;
  border: 1px solid rgba(128, 137, 150, 0.3);
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.filter-nav li a.active {
  background-color: #7b68ee;
  color: #fff;
  border-color: #7b68ee;
}

/*======== dot-action-wrap =========*/
.dot-action-wrap .dropdown-menu {
  margin-top: 10px;
  border-color: rgba(128, 137, 150, 0.1);
  padding-right: 10px;
  padding-left: 10px;
  min-width: 9rem;
}

.dot-action-wrap .dropdown-item {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #808996;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 7px 15px;
  font-size: 15px;
}
.dot-action-wrap .dropdown-item:hover {
  color: #333f57;
}

/*======== back-to-top =========*/
#back-to-top {
  position: fixed;
  right: -150px;
  bottom: 30px;
  z-index: 9999;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  color: #333f57;
  font-size: 16px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
#back-to-top:hover {
  background-color: #7b68ee;
  color: #fff;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
#back-to-top.btn-active {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

/*======== owl-trigger-action =========*/
.owl-trigger-action .owl-nav div {
  position: absolute;
  top: 50%;
  left: -70px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  line-height: 40px;
  -webkit-box-shadow: 0 5px 30px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 5px 30px rgba(82, 85, 90, 0.1);
  box-shadow: 0 5px 30px rgba(82, 85, 90, 0.1);
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: rgba(51, 63, 87, 0.5);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 1280px) {
  .owl-trigger-action .owl-nav div {
    left: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-trigger-action .owl-nav div {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .owl-trigger-action .owl-nav div {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .owl-trigger-action .owl-nav div {
    display: none;
  }
}
.owl-trigger-action .owl-nav div.owl-next {
  left: auto;
  right: -70px;
}
@media (max-width: 1280px) {
  .owl-trigger-action .owl-nav div.owl-next {
    right: -10px;
  }
}
.owl-trigger-action .owl-nav div:hover {
  color: #7b68ee;
  -webkit-box-shadow: 0 5px 30px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 5px 30px rgba(82, 85, 90, 0.2);
  box-shadow: 0 5px 30px rgba(82, 85, 90, 0.2);
}

.owl-trigger-action .owl-dots {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.owl-trigger-action .owl-dots div {
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(51, 63, 87, 0.1);
  border: 2px solid transparent;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 2px;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.owl-trigger-action .owl-dots div.active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  background-color: transparent;
  border-color: #7b68ee;
}

/*==== owl-trigger-action-2 ====*/
.owl-trigger-action-2 .owl-nav div {
  left: 45px;
}
.owl-trigger-action-2 .owl-nav div.owl-next {
  right: 45px;
}

/*==== owl-trigger-action-3 ====*/
.owl-trigger-action-3 .owl-nav div {
  left: 20px;
  width: 35px;
  height: 35px;
  line-height: 32px;
  font-size: 18px;
}
.owl-trigger-action-3 .owl-nav div.owl-next {
  right: 20px;
}

.owl-trigger-action-3 .owl-dots {
  margin-top: 0;
  position: absolute;
  bottom: 20px;
}
.owl-trigger-action-3 .owl-dots div {
  background-color: rgba(255, 255, 255, 0.7);
}
.owl-trigger-action-3 .owl-dots div.active {
  background-color: #fff;
  border-color: #fff;
}

/*==== stroke-shape ====*/
.stroke-shape {
  position: relative;
  width: 45px;
  height: 4px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: linear-gradient(270deg, #9f8fff 0, #7b68ee 100%);
}

/*-===============================
    line-bg
===============================-*/
.line-bg {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  top: 0;
  opacity: 0.7;
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  transform: rotate(10deg);
}
.line-bg:before {
  position: absolute;
  top: -60%;
  left: 0;
  content: "";
  height: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-animation: run 5s 0s infinite;
  animation: run 5s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.3, 0.27, 0, 0.98);
  animation-timing-function: cubic-bezier(0.3, 0.27, 0, 0.98);
}

.line-bg1 {
  left: 14%;
}
.line-bg1:before {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.line-bg2 {
  left: 30%;
}
.line-bg2:before {
  -webkit-animation-delay: 2.6s;
  animation-delay: 2.6s;
}

.line-bg3 {
  left: 45%;
}
.line-bg3:before {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.line-bg4 {
  left: 60%;
}
.line-bg4:before {
  -webkit-animation-delay: 3.6s;
  animation-delay: 3.6s;
}

.line-bg5 {
  left: 75%;
}
.line-bg5:before {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.line-bg6 {
  left: 88%;
}
.line-bg6:before {
  -webkit-animation-delay: 4.6s;
  animation-delay: 4.6s;
}

/*========= block-card ========*/
.block-card {
  background-color: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  border: 1px solid rgba(128, 137, 150, 0.1);
  padding: 30px;
}

.block-card-header {
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  padding-bottom: 20px;
}

.block-card-body {
  padding-top: 20px;
}

.animated--grow-in {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
}

/*===== typing indicator ======*/
.typing-indicator {
  align-items: center;
  display: flex;
  height: 13px;
}

.typing-indicator-dot {
  background-color: rgba(128, 137, 150, 0.6);
  -webkit-animation: mercuryTypingAnimation 1.4s infinite ease-in-out;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  margin-right: 3px;
  width: 7px;
}
.typing-indicator-dot:nth-child(1) {
  -webkit-animation-delay: 200ms;
}
.typing-indicator-dot:nth-child(2) {
  -webkit-animation-delay: 300ms;
}
.typing-indicator-dot:nth-child(3) {
  -webkit-animation-delay: 400ms;
}

/*===== blockquote-box ======*/
.blockquote-box {
  border-left: 7px solid rgba(128, 137, 150, 0.2);
  padding-left: 20px;
  position: relative;
}
.blockquote-box p {
  padding-right: 30px;
  font-style: italic;
}
.blockquote-box .la-quote-right {
  position: absolute;
  bottom: 10px;
  right: 5px;
  font-size: 50px;
  opacity: 0.2;
}

/*====== post-nav ======*/
.post-nav {
  font-weight: 600;
}
.post-nav li {
  width: 33%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .post-nav li {
    width: 48%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .post-nav li {
    width: 48%;
  }
}
.post-nav li a {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  padding: 10px;
  display: block;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.post-nav li i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.post-nav li.prev-post a {
  padding-left: 40px;
}
.post-nav li.prev-post a:hover {
  color: #333f57;
}
.post-nav li.prev-post a:hover i {
  left: 10px;
}
.post-nav li.prev-post i {
  left: 15px;
}
.post-nav li.next-post a {
  padding-right: 40px;
}
.post-nav li.next-post a:hover {
  color: #333f57;
}
.post-nav li.next-post a:hover i {
  right: 10px;
}
.post-nav li.next-post i {
  right: 15px;
}
.post-nav .text-truncate {
  max-width: 12rem;
  display: block;
}

/*====== destination-content ========*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .destination-content .btn-box {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .destination-content .btn-box {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .destination-content .btn-box {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-heading-content {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-heading-content {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-heading-content {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-content {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .blog-content {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .blog-content {
    margin-bottom: 50px;
  }
}

.icon-element {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #7b68ee;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  font-size: 26px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
}
.icon-element:hover {
  color: #fff;
}

.icon-element-sm {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}

.icon-element-lg {
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 35px;
}

.icon-element-white {
  background-color: #fff;
  color: #333f57;
}
.icon-element-white:hover {
  color: #333f57;
}

.icon-block-wrap .icon-block {
  margin: 4px;
}

/*======= icon-block =========*/
.icon-block {
  background-color: #fff;
  border: 1px solid rgba(128, 137, 150, 0.1);
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.icon-block .icon-element {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

/*====================================================
    contact-area
 ====================================================*/
.label-text {
  /* font-size: 14px; */
  font-size: 12px;
  margin: -0.5rem 0 0.1rem 0 !important;
  color: #333f57;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
}

.form-box .form-group {
  position: relative;
  margin-bottom: 0.6rem;
}
.form-box .form-group .form-icon {
  position: absolute;
  /* top: 16px;
  left: 15px;
  color: #808996;
  font-size: 18px; */
  top: 11px;
  left: 11px;
  color: #808996;
  font-size: 15px;
}
.form-box .form-group .form-icon-2 {
  top: 12px;
}
.form-box .form-group .dollar-icon {
  top: 8px;
  font-size: 16px;
}
.form-box .form-group .input-btn-append {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 320px) {
  .form-box .form-group .input-btn-append {
    position: inherit;
    top: auto;
    right: auto;
    margin-top: 10px;
  }
}

.form-box .form-control {
  height: auto;
  line-height: inherit;
  /* padding: 12px 20px 12px 40px; */
  padding: 6px 30px;
  font-size: 15px;
  color: #333f57;
  border: 1px solid rgba(128, 137, 150, 0.3);
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.form-box .form-control[readonly] {
  background-color: #fff;
}
.form-box .form-control:focus {
  border-color: rgba(128, 137, 150, 0.6);
  -webkit-box-shadow: 0 0 0 0;
  -moz-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}
.form-box .form-control::-webkit-input-placeholder {
  color: #808996;
}
.form-box .form-control:-ms-input-placeholder {
  color: #808996;
}
.form-box .form-control::placeholder {
  color: #808996;
  font-size: 12px;
}

.form-box .form-control-sm {
  padding: 7px 14px 7px 40px;
}

.form-box .form-control-long {
  width: 360px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .form-box .form-control-long {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .form-box .form-control-long {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .form-box .form-control-long {
    width: 100%;
  }
}

.form-box .form-control-styled {
  border-left: 5px solid #7b68ee;
  border-top: none;
  border-right: none;
  border-bottom: none;
  -webkit-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
}
.form-box .form-control-styled:focus {
  border-left-color: #533ae9;
  -webkit-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
}

.form-box .message-control {
  height: 160px;
}

/*======== price-range-wrap =======*/
.price-range-wrap .form-control-sm {
  width: 120px;
}

/*======== price-range-wrap-2 =======*/
.price-range-wrap-2 .form-control-sm {
  width: 96px;
}

/*============ Booking Page =========*/
.payment-logo {
  position: absolute;
  right: 20px;
  top: 21px;
}

.payment-tab-toggle {
  padding-right: 20px;
  padding-left: 20px;
}
.payment-tab-toggle > label {
  cursor: pointer;
  display: block;
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: 600;
  color: #333f57;
}
.payment-tab-toggle > label:before {
  background-color: #fff;
  border: 2px solid #ddd;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.payment-tab-toggle > label:after {
  background-color: #f9b851;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 5px;
  top: 23px;
  width: 8px;
  height: 8px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.payment-tab-toggle > input {
  position: absolute;
  visibility: hidden;
}
.payment-tab-toggle > input:checked ~ label:before {
  border-color: #f9b851;
}
.payment-tab-toggle > input:checked ~ label:after {
  background-color: #f9b851;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/*====== payment-tab =======*/
.payment-tab {
  background-color: #fff;
  max-height: 55px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  margin-bottom: 15px;
}
.payment-tab.is-active {
  max-height: 400px;
}
.payment-tab.is-active .payment-tab-content {
  visibility: visible;
  opacity: 1;
}

.payment-tab-content {
  padding: 0 20px 20px 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/*===== coupon-widget =======*/
.coupon-widget .theme-btn {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

/*====================================================
    invoice-area
 ====================================================*/
.my-table table tr th {
  color: #333f57;
  font-weight: 600;
  border-bottom-width: 1px;
}

.my-table table tr th,
.my-table table tr td {
  padding: 10px 20px;
}

.my-table table tr td {
  font-weight: 500;
}

.my-table .invoice-table-two {
  margin-bottom: 0;
}
.my-table .invoice-table-two tr th,
.my-table .invoice-table-two tr td {
  border-top: none;
  padding: 5px 0 5px 0;
}
.my-table .invoice-table-two tr td {
  text-align: right;
}

.coming-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coming-inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.coming-inner:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333f57;
  opacity: 0.7;
  z-index: -1;
}

.blockquote-item {
  border-left: none;
  background-image: url(../images/video-img.jpg);
  background-size: cover;
  background-position: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 1;
  padding: 40px;
  position: relative;
}
.blockquote-item .blockquote__icon {
  position: absolute;
  right: 30px;
  bottom: 20px;
  font-size: 100px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
}
.blockquote-item .blockquote__text {
  line-height: 30px;
  font-weight: 500;
  font-style: italic;
  color: #fff;
}
.blockquote-item .blockquote__meta {
  font-weight: 500;
  margin-top: 16px;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
}
.blockquote-item .blockquote__meta span {
  color: #808996;
  font-size: 18px;
  margin-left: 5px;
}
.blockquote-item:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333f57;
  opacity: 0.9;
  z-index: -1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*====================================================
    breadcrumb-area
 ====================================================*/
.breadcrumb-area {
  padding-top: 220px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
}
@media (max-width: 480px) {
  .breadcrumb-area {
    padding-top: 250px;
  }
}

.breadcrumb--area {
  padding-top: 250px;
  padding-bottom: 160px;
}

/* bread-overlay */
.bread-overlay {
  position: relative;
  z-index: 1;
}
.bread-overlay:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 50, 47, 0.8);
  z-index: -1;
}

/* bread-svg */
.bread-svg {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  pointer-events: none;
}
.bread-svg svg {
  width: 100%;
  height: 140px;
  stroke: none;
  fill: #fff;
}

/* bread-bg */
.bread-bg {
  background-image: url("../images/bread-bg.jpg");
}

.bread-bg-2 {
  background-image: url("../images/bread-bg2.jpg");
}

.bread-bg-3 {
  background-image: url("../images/bread-bg3.jpg");
}

.user-bread-bg {
  background-image: url("../images/user-bread-img.jpg");
}

/* breadcrumb-content */
@media (max-width: 480px) {
  .breadcrumb-content .section-heading.pb-3 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 480px) {
  .breadcrumb-content .sec__title {
    font-size: 32px !important;
  }
}

@media (max-width: 480px) {
  .breadcrumb-content .sec__title.font-size-28 {
    font-size: 26px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content .sec__title.font-size-60 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .breadcrumb-content .sec__title.font-size-60 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .breadcrumb-content .sec__title.font-size-60 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}

@media (max-width: 480px) {
  .breadcrumb-content .bread-thumb {
    width: 80px;
    height: 80px;
    bottom: auto;
    position: inherit;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .breadcrumb-content .bread-list {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .breadcrumb-content .bread-list {
    margin-top: 15px;
  }
}

.breadcrumb-content .contact-form-action .form-control {
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.breadcrumb-content .contact-form-action .form-group .submit-btn {
  -webkit-border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  border-radius: 0 30px 30px 0;
  color: #333f57;
  background-color: rgba(128, 137, 150, 0.2);
  padding-right: 25px;
  padding-left: 25px;
  top: 1px;
  right: 0;
  line-height: 64px;
}
.breadcrumb-content .contact-form-action .form-group .submit-btn:hover {
  background-color: #7b68ee;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content-2 .section-heading {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .breadcrumb-content-2 .section-heading {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .breadcrumb-content-2 .section-heading {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timestamp {
    padding-left: 0 !important;
    border-left: 0 !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .timestamp {
    padding-left: 0 !important;
    border-left: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .timestamp {
    padding-left: 0 !important;
    border-left: 0 !important;
  }
}

/*====== bread list =======*/
.bread-list {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
}
.bread-list li {
  display: inline-block;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
}
.bread-list li a {
  color: #fff;
}
.bread-list li a:before {
  background-color: #fff;
  height: 1px;
}
.bread-list li a:hover {
  color: #fff;
}
.bread-list li + li {
  padding-left: 10px;
  margin-left: 10px;
}
.bread-list li + li:after {
  position: absolute;
  content: "\f0da";
  font-family: "Line Awesome Free";
  font-weight: 900;
  top: 60%;
  font-size: 12px;
  left: -10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*========= bread-list-2 ========*/
.bread-list-2 {
  background-color: rgba(128, 137, 150, 0.1);
}
.bread-list-2 li {
  color: rgba(128, 137, 150, 0.9);
}
.bread-list-2 li a {
  color: #333f57;
}
.bread-list-2 li a:before {
  background-color: #7b68ee;
}
.bread-list-2 li a:hover {
  color: #7b68ee;
}

.bread-thumb {
  position: relative;
  bottom: -35px;
  border-width: 5px !important;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .bread-btns {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .bread-btns {
    padding-top: 20px;
  }
}

/*========= hover-tooltip-box ========*/
.hover-tooltip-box {
  position: relative;
}
.hover-tooltip-box .hover-tooltip {
  position: absolute;
  top: -65px;
  left: 0;
  width: 300px;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.09);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.09);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.09);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hover-tooltip-box .hover-tooltip {
    width: 240px;
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hover-tooltip-box .hover-tooltip {
    width: 200px;
    left: auto;
    right: 0;
  }
}
.hover-tooltip-box .hover-tooltip a {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.hover-tooltip-box .hover-tooltip a:hover {
  color: #7b68ee;
}
.hover-tooltip-box .hover-tooltip:after {
  position: absolute;
  content: "";
  bottom: -6px;
  left: 15px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hover-tooltip-box .hover-tooltip:after {
    left: auto;
    right: 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hover-tooltip-box .hover-tooltip:after {
    left: auto;
    right: 15px;
  }
}
.hover-tooltip-box:hover .hover-tooltip {
  top: -70px;
  opacity: 1;
  visibility: visible;
}

.category-link {
  font-size: 16px;
  position: relative;
  padding-left: 12px;
}
.category-link a {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.category-link a:hover {
  color: #7b68ee;
}
.category-link:after {
  position: absolute;
  content: "";
  top: 12px;
  left: 0;
  width: 4px;
  height: 4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(128, 137, 150, 0.6);
}

/*======= header-area ========*/
.header-area {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1010;
}
@media (max-width: 1199px) {
  .header-area {
    background-color: #333f57;
  }
}
.header-area:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  opacity: 0.1;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-top-bar {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .header-top-bar {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-menu-wrapper {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .header-menu-wrapper {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.header-menu-wrapper.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  background: #333f57 !important;
}
.header-menu-wrapper.header-fixed .logo .sticky-logo-hide {
  display: none;
}
.header-menu-wrapper.header-fixed .logo .sticky-logo-show {
  display: block;
}
.header-menu-wrapper.header-fixed .logo .btn-gray.text-color {
  color: #fff !important;
}
.header-menu-wrapper.header-fixed .menu-toggle-black {
  background-color: rgba(255, 255, 255, 0.1);
}
.header-menu-wrapper.header-fixed .menu-toggle-black .menu__bar {
  background: #fff;
}
.header-menu-wrapper.header-fixed .main-menu-2 > ul > li > a {
  color: rgba(255, 255, 255, 0.8);
}
.header-menu-wrapper.header-fixed .main-menu-2 > ul > li > a:hover {
  color: #fff;
}

/*=== logo ===*/
.logo {
  padding-top: 25px;
  padding-bottom: 25px;
  z-index: 2;
}

.sticky-logo-show {
  display: none;
}

/*=== quick-search-form ===*/
.quick-search-form {
  width: 310px;
  position: relative;
  margin-left: 40px;
  padding-left: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  flex-shrink: 0;
}
@media (max-width: 1199px) {
  .quick-search-form {
    display: none !important;
  }
}

/*=== header-search ===*/
.header-search .form-icon {
  position: absolute;
  left: 15px;
  top: 20px;
  color: #fff;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.header-search input {
  background-color: transparent;
  padding: 14px 10px 14px 50px;
  border: none;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: #333f57;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.header-search input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.header-search.active .form-icon {
  color: #333f57;
}

.header-search.active input {
  background-color: #fff;
}
.header-search.active input::-webkit-input-placeholder {
  color: rgba(127, 137, 150, 0.8);
}
.header-search.active input::-moz-placeholder {
  color: rgba(127, 137, 150, 0.8);
}
.header-search.active input:-ms-input-placeholder {
  color: rgba(127, 137, 150, 0.8);
}
.header-search.active input::placeholder {
  color: rgba(127, 137, 150, 0.8);
}

.header-search.active .instant-results {
  opacity: 1;
  visibility: visible;
}

/*=== instant-results ===*/
.instant-results {
  background-color: #fff;
  border-top: 1px solid rgba(127, 137, 150, 0.1);
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 20px 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 20px 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 20px 40px rgba(82, 85, 90, 0.2);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  z-index: 1024;
  opacity: 0;
  visibility: hidden;
}
.instant-results a {
  padding: 8px 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #808996;
  font-size: 15px;
}
.instant-results a:hover {
  background-color: rgba(127, 137, 150, 0.1);
  color: #333f57;
}
.instant-results .icon-element {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 22px;
}
.instant-results .icon-element:after {
  display: none;
}

/*======== header-top-info =========*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-info {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-top-info {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .header-top-info {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

.header-top-info a {
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-top-info a:hover {
  color: #fff;
}

.header-top-info .social-profile li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}

.header-top-info .list-items li {
  font-size: 14px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.6);
}
.header-top-info .list-items li + li {
  margin-left: 10px;
}
.header-top-info .list-items li a {
  color: rgba(255, 255, 255, 0.6);
}
.header-top-info .list-items li a:before {
  background-color: #fff;
}
.header-top-info .list-items li a:hover {
  color: #fff;
}

@media (max-width: 320px) {
  .header-top-info .list-items-style li {
    border-right: 0;
    padding-right: 0;
    padding-bottom: 4px;
  }
}

.header-top-info .user-chosen-select-container .chosen-container {
  width: 110px !important;
}

.header-top-info .user-chosen-select-container .chosen-drop {
  border-color: #fff;
  padding-right: 5px;
}

.header-top-info
  .user-chosen-select-container
  .chosen-container-active.chosen-with-drop
  .chosen-single {
  color: #333f57;
}

.header-top-info .user-chosen-select-container .chosen-single {
  background: rgba(255, 255, 255, 0.07);
  color: rgba(255, 255, 255, 0.8);
  padding: 6px 12px;
}
.header-top-info .user-chosen-select-container .chosen-single div {
  top: 8px;
}

.header-top-info .user-chosen-select-container .chosen-search {
  display: none;
}

.header-top-info .user-chosen-select-container .chosen-results li {
  padding-right: 12px;
  padding-left: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-top-info.justify-content-end {
    padding-top: 5px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-top-info.justify-content-end {
    padding-top: 5px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .header-top-info.justify-content-end {
    padding-top: 5px;
    flex-direction: column;
  }
}

/*======== login-and-signup-wrap =========*/
.login-and-signup-wrap a {
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
}
.login-and-signup-wrap a:hover {
  color: #fff;
}

/*======== main-menu-content =========*/
.main-menu-content {
  padding-top: 20px;
  text-align: right;
  padding-right: 28px;
  z-index: 10;
}
@media (max-width: 1199px) {
  .main-menu-content {
    display: none;
    text-align: left;
    width: 100%;
    padding-left: 0 !important;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 30px;
  }
}

/*======== main-menu-content-2 =========*/
.main-menu-content-2 {
  margin-left: 30px;
  padding-left: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 1199px) {
  .main-menu-content-2 {
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
  }
}

/*======== main-menu =========*/
.main-menu ul li {
  display: inline-block;
  text-transform: capitalize;
  padding-top: 10px;
  padding-right: 23px;
  position: relative;
}
@media (max-width: 1199px) {
  .main-menu ul li:first-child {
    padding-top: 0;
  }
}
@media (max-width: 1199px) {
  .main-menu ul li {
    display: block;
    padding-right: 0;
  }
}
.main-menu ul li a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 600;
  display: block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  padding: 0 0 28px 0;
}
@media (max-width: 1199px) {
  .main-menu ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }
}
.main-menu ul li a .la-angle-down {
  font-size: 12px;
}
@media (max-width: 1199px) {
  .main-menu ul li a .la-angle-down {
    display: none;
  }
}
.main-menu ul li a:hover {
  color: #fff;
}
.main-menu ul li .dropdown-menu-item {
  text-align: left;
  position: absolute;
  top: 70px;
  left: 0;
  width: 240px;
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 1199px) {
  .main-menu ul li .dropdown-menu-item {
    opacity: 1;
    visibility: visible;
    display: none;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    margin-top: 10px;
    height: 260px;
    overflow-x: auto;
    overflow-y: auto;
  }
}
.main-menu ul li .dropdown-menu-item li {
  display: block;
  padding-top: 0;
  padding-right: 0;
}
.main-menu ul li .dropdown-menu-item li a {
  color: #808996;
  display: block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: capitalize;
  padding: 5px 25px;
  font-weight: 500;
  position: relative;
  font-size: 15px;
}
.main-menu ul li .dropdown-menu-item li a .ribbon {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-menu ul li .dropdown-menu-item li a:hover {
  color: #7b68ee;
}
.main-menu ul li .dropdown-menu-item li a:hover .ribbon {
  background-color: #ffbb3d;
  color: #fff;
}
.main-menu ul li .mega-menu {
  width: 700px;
  left: -375px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1199px) {
  .main-menu ul li .mega-menu {
    width: 100%;
    left: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
.main-menu ul li .mega-menu .mega-menu-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.main-menu ul li .mega-menu .mega-menu-item + .mega-menu-item {
  border-left: 1px solid rgba(127, 137, 150, 0.1);
}
.main-menu ul li:last-child {
  padding-right: 0;
}

/*======== main-menu-2 =========*/
.main-menu-2 ul li a {
  color: rgba(51, 63, 27, 0.8);
}
.main-menu-2 ul li a .drop-menu-toggle {
  background-color: rgba(128, 137, 150, 0.1);
}
.main-menu-2 ul li a:hover {
  color: #333f57;
}

@media (min-width: 1200px) {
  .main-menu ul li:hover .dropdown-menu-item {
    top: 60px;
    opacity: 1;
    visibility: visible;
  }
}

/*======== nav-right-content =========*/
.nav-right-content {
  position: relative;
  padding-top: 20px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .nav-right-content {
    padding-top: 0;
  }
}
@media (max-width: 1199px) {
  .nav-right-content .add-listing-btn-hide {
    display: none;
  }
}

.add-listing-btn-show {
  display: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.add-listing-btn-show:hover {
  color: #fff;
}
@media (max-width: 1199px) {
  .add-listing-btn-show {
    display: block;
  }
}

.menu-full-width {
  display: flex;
  position: relative;
}
@media (max-width: 1199px) {
  .menu-full-width {
    flex-wrap: wrap;
  }
}
@media (max-width: 1199px) {
  .menu-full-width .logo {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (max-width: 1199px) {
  .menu-full-width .login-and-signup-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/*==== menu-toggle ====*/
.menu-toggle {
  width: 50px;
  height: 42px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: none;
}
@media (max-width: 1199px) {
  .menu-toggle {
    display: block;
  }
}
.menu-toggle .menu__bar {
  display: block;
  height: 2px;
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.menu-toggle .menu__bar:nth-child(1) {
  top: 10px;
}
.menu-toggle .menu__bar:nth-child(2) {
  top: 20px;
}
.menu-toggle .menu__bar:nth-child(3) {
  top: 30px;
}
.menu-toggle.active .menu__bar:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 20px;
}
.menu-toggle.active .menu__bar:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.menu-toggle.active .menu__bar:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
}

.menu-toggle-black {
  background-color: rgba(128, 137, 150, 0.1);
}
.menu-toggle-black .menu__bar {
  background: #808996;
}

.drop-menu-toggle {
  font-size: 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 35px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: none;
}
@media (max-width: 1199px) {
  .drop-menu-toggle {
    display: block;
  }
}
.drop-menu-toggle.active .la:before {
  content: "\f068";
}
.drop-menu-toggle.active,
.drop-menu-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/*====== sticky-content-nav =======*/
.sticky-content-navbar.is-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1025;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  padding-top: 10px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
}

/*====== sticky-content-nav =======*/
.sticky-content-nav .btn-gray {
  -webkit-box-shadow: 0 0 30px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 30px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 30px rgba(82, 85, 90, 0.1);
  border: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky-content-nav .btn-gray {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .sticky-content-nav .btn-gray {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .sticky-content-nav .btn-gray {
    margin-bottom: 10px;
  }
}
.sticky-content-nav .btn-gray + .btn-gray {
  margin-left: 10px;
}
.sticky-content-nav .btn-gray.active {
  color: #333f57;
}

/*====================================================
    dashboard-header
 ====================================================*/
.dashboard-header {
  border-bottom: 1px solid rgba(13, 35, 62, 0.1);
}
.dashboard-header .header-menu-wrapper:after {
  background-color: #fff;
}
.dashboard-header .main-menu-content nav ul li a {
  color: #333f57;
}
.dashboard-header .main-menu-content nav ul li a:hover {
  color: #7b68ee;
}

/*===== modal =====*/
@media (min-width: 992px) {
  .modal-form .modal-dialog.modal-lg {
    width: 500px;
  }
}

@media (max-width: 480px) {
  .modal-form .modal-dialog.modal-lg {
    width: auto;
  }
}

.modal-form .modal-top {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 38px;
  padding: 25px 30px 23px 30px;
}
.modal-form .modal-top .close-arrow {
  opacity: 1;
}
.modal-form .modal-top .close-arrow span {
  font-size: 30px;
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.modal-form .modal-top .close-arrow span:hover {
  color: #7b68ee;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.modal-form .modal-top .modal-title {
  font-size: 20px;
  color: #333f57;
}
@media (max-width: 320px) {
  .modal-form .modal-top .modal-title {
    font-size: 16px;
  }
}
.modal-form .modal-top .modal-title span {
  color: #808996;
  font-size: 30px;
}
.modal-form .modal-top .modal-sub {
  font-weight: 600;
}

.modal-form .alert-content .warning-icon {
  color: #f9b851;
  font-size: 70px;
  display: inline-block;
}

.modal-form .alert-content .modal-title {
  line-height: 25px;
  font-weight: 600;
}

.modal-form .contact-form-action {
  padding: 0 30px 30px 30px;
}

.modal-form .btn-box .theme-btn {
  line-height: 40px;
}

@media (max-width: 320px) {
  .modal-form .btn-box.text-right {
    text-align: left;
  }
}

.modal-container.fade .modal-dialog {
  -webkit-transform: scale(0.8) translate(0, 0);
  -moz-transform: scale(0.8) translate(0, 0);
  -ms-transform: scale(0.8) translate(0, 0);
  -o-transform: scale(0.8) translate(0, 0);
  transform: scale(0.8) translate(0, 0);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* modal container */
.modal-container .modal-content {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.modal-container .modal-header {
  padding: 18px 25px;
  border-bottom: none;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  border-top-left-radius: calc(8px - 1px);
  border-top-right-radius: calc(8px - 1px);
}
.modal-container .modal-header:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333f57;
  opacity: 0.8;
  z-index: -1;
  border-top-left-radius: calc(8px - 1px);
  border-top-right-radius: calc(8px - 1px);
}

.modal-container .modal-title {
  color: #fff;
  font-size: 18px;
}

.modal-container .close {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 30px;
  text-shadow: none;
  opacity: 0.7;
  outline: none;
}
.modal-container .close:hover {
  opacity: 1 !important;
}

.modal-container .modal-body {
  padding: 18px 25px;
}

.modal-container .theme-btn {
  border: none;
}

.modal-container .modal-dialog {
  -webkit-transform: scale(0.8) translate(0, 0);
  -moz-transform: scale(0.8) translate(0, 0);
  -ms-transform: scale(0.8) translate(0, 0);
  -o-transform: scale(0.8) translate(0, 0);
  transform: scale(0.8) translate(0, 0);
}

.modal-container.show {
  display: block;
}
.modal-container.show .modal-dialog {
  -webkit-transform: scale(1) translate(0, 0);
  -moz-transform: scale(1) translate(0, 0);
  -ms-transform: scale(1) translate(0, 0);
  -o-transform: scale(1) translate(0, 0);
  transform: scale(1) translate(0, 0);
}

.mh-bg {
  background-image: url("../images/login-bg.jpg");
}

.mh-bg-2 {
  background-image: url("../images/signup-bg.jpg");
}

.user-action-meta .lost-pass-btn {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
}
.user-action-meta .lost-pass-btn:hover {
  color: #7b68ee;
}

/*====== copy-to-clipboard =======*/
.copy-to-clipboard {
  position: relative;
  z-index: 1;
}

/*====== text-success-message=======*/
.text-success-message {
  background-color: #40cc6f;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 3px 6px;
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  font-size: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: 500;
}
.text-success-message.active {
  top: -40px;
  opacity: 1;
  visibility: visible;
}
.text-success-message:after {
  position: absolute;
  content: "";
  bottom: -4px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  background-color: #40cc6f;
  width: 8px;
  height: 8px;
}

/*======== faq area ======*/
.accordion-item .card-header {
  padding: 0;
  background-color: #fff;
  border-bottom: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.accordion-item .card-header .btn {
  width: 100%;
  color: #333f57;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 20px 12px 40px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  position: relative;
  text-align: left;
}
.accordion-item .card-header .btn i {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 19px;
  display: none;
}
.accordion-item .card-header .btn:after {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  width: 8px;
  height: 8px;
  background-color: #333f57;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-item .card-header .btn[aria-expanded="false"] .la-plus {
  display: block;
}
.accordion-item .card-header .btn[aria-expanded="true"] .la-minus {
  display: block;
}
.accordion-item .card-header .btn[aria-expanded="true"]:after {
  opacity: 1;
}

.accordion-item .card {
  -webkit-box-shadow: 0 0 14px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 14px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 14px rgba(82, 85, 90, 0.1);
  border-color: rgba(128, 137, 150, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.accordion-item .card + .card {
  margin-top: 10px;
}

.accordion-item .card-body {
  padding: 0 20px 20px 20px;
}
.accordion-item .card-body p {
  font-weight: 500;
  font-size: 15px;
}

/*====================================================
    pricing-area
 ====================================================*/
.price-item {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.price-item:hover .price-head:before {
  bottom: -120px;
}
.price-item:hover .price-head:after {
  left: -170px;
}

.price-head {
  background-color: #f5f7fc;
  padding-top: 40px;
  padding-bottom: 105px;
  position: relative;
  color: #fff;
  overflow: hidden;
  text-align: center;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.price-head .ribbon {
  padding-right: 30px;
  padding-left: 30px;
  position: absolute;
  top: 24px;
  left: -35px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.price-head .price__title {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}
.price-head:before,
.price-head:after {
  content: "";
  position: absolute;
  bottom: -150px;
  right: -60px;
  width: 200px;
  height: 200px;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.05;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.price-head:after {
  bottom: 20px;
  right: auto;
  left: -200px;
  width: 300px;
  height: 300px;
}

.price-box {
  width: 140px;
  height: 140px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  margin-top: -75px;
  position: relative;
  text-align: center;
}
.price-box .price__text {
  font-size: 30px;
  font-weight: 600;
}
.price-box .price__text-meta {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.price-list {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 30px 30px 50px 30px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.price-list li + li {
  border-top: 1px solid rgba(127, 136, 150, 0.1);
  padding-top: 12px;
  margin-top: 12px;
}

.price-btn-box {
  margin-top: -23px;
}

.recommended-wrap {
  font-weight: 600;
}
.recommended-wrap i {
  display: block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #40cc6f;
  color: #fff;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  margin-bottom: 10px;
}

.pricing-active {
  margin-top: -30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-active {
    margin-top: 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .pricing-active {
    margin-top: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .pricing-active {
    margin-top: 0;
  }
}
.pricing-active .price-head {
  background-color: #40cc6f;
}
.pricing-active .price-content .price-number:before {
  background-color: #40cc6f;
}
.pricing-active:hover .price-head,
.pricing-active:hover .price-number:before {
  background-color: #40cc6f;
}

/*====================================================
    about-area
 ====================================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .about-content {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .about-content {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0 !important;
  }
}

/*====================================================
    hero-wrapper
 ====================================================*/
.hero-wrapper {
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  z-index: 1;
  color: #fff;
  padding-top: 250px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-wrapper {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-wrapper {
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hero-wrapper {
    padding-bottom: 50px;
  }
}
.hero-wrapper #fullscreen-slide-container {
  position: absolute !important;
}

@media (max-width: 425px) {
  .hero-wrapper-4 {
    padding-top: 320px;
  }
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

/*====== hero-bg ======*/
.hero-bg {
  background-image: url("../images/hero-bg.jpg");
}

.hero-bg-2 {
  background-image: url("../images/hero-bg2.jpg");
}

.hero-bg-3 {
  background-image: url("../images/hero-bg3.jpg");
}

.hero-bg-4 {
  background-image: url("../images/hero-bg4.jpg");
}

.hero-bg-5 {
  background-image: url("../images/hero-bg5.jpg");
}

/*====== hero-heading ======*/
.hero-heading .section-heading .sec__desc {
  font-size: 20px;
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.9);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-heading .section-heading .sec__desc {
    font-size: 18px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-heading .section-heading .sec__desc {
    font-size: 18px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hero-heading .section-heading .sec__desc {
    font-size: 18px;
    margin-top: 10px;
  }
}

.hero-heading .section-heading .sec__title {
  font-size: 45px;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-heading .section-heading .sec__title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-heading .section-heading .sec__title {
    font-size: 35px;
  }
}
.hero-heading .section-heading .sec__title .cd-words-wrapper b {
  font-weight: 700;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hero-heading .section-heading .sec__title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .highlight-lists {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .highlight-lists {
    flex-wrap: wrap;
  }
}

.main-search-input {
  margin-top: 50px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px 25px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-search-input {
    margin-top: 35px;
    margin-bottom: 30px;
    flex-direction: column;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .main-search-input {
    margin-top: 35px;
    margin-bottom: 30px;
    flex-direction: column;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .main-search-input {
    margin-top: 35px;
    margin-bottom: 30px;
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-search-input.mt-0 {
    margin-top: 35px !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .main-search-input.mt-0 {
    margin-top: 35px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .main-search-input.mt-0 {
    margin-top: 35px !important;
  }
}

.main-search-input-item {
  width: 25%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-search-input-item {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .main-search-input-item {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .main-search-input-item {
    width: 100%;
  }
}
.main-search-input-item + .main-search-input-item {
  margin-left: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-search-input-item + .main-search-input-item {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .main-search-input-item + .main-search-input-item {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .main-search-input-item + .main-search-input-item {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-search-input-btn {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .main-search-input-btn {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .main-search-input-btn {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-search-input-btn .theme-btn {
    width: 100%;
    display: block;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .main-search-input-btn .theme-btn {
    width: 100%;
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .main-search-input-btn .theme-btn {
    width: 100%;
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .highlight-search-desc {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .highlight-search-desc {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .highlight-search-desc {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

/*======== highlighted-categories =========*/
.highlighted-categories {
  margin-top: 49px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .highlighted-categories {
    text-align: center;
    margin-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .highlighted-categories {
    text-align: center;
    margin-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .highlighted-categories {
    text-align: center;
    margin-top: 30px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.highlighted__title {
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  font-family: "Be Vietnam", "sans-serif";
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .highlighted__title br {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .highlighted__title br {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .highlighted__title br {
    display: none;
  }
}

.cat-arrow-icon {
  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  transform: rotate(50deg);
  position: relative;
  top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cat-arrow-icon {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cat-arrow-icon {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cat-arrow-icon {
    display: none;
  }
}

/*======== highlighted-categories-2 =========*/
.highlighted-categories-2 .cat-arrow-icon {
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
  transform: rotate(-50deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .highlight-lists {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .highlight-lists {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .highlight-lists {
    margin-top: 0 !important;
  }
}

/*=============== main-search-input-2 ===============*/
.main-search-input-2 {
  -webkit-box-shadow: 0px 10px 40px 0 rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 10px 40px 0 rgba(0, 0, 0, 0.13);
  box-shadow: 0px 10px 40px 0 rgba(0, 0, 0, 0.13);
  padding: 30px;
  margin-bottom: -80px;
}

.hero-svg-content {
  position: absolute;
  bottom: -42px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 210px;
  height: 100px;
  line-height: 90px;
}
.hero-svg-content i {
  color: #333f57;
  position: relative;
  z-index: 1;
  font-size: 14px;
}
.hero-svg-content:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/hero-shape.png");
  top: 0;
  left: 0;
  z-index: -1;
}

/*====================================================
    hiw-area
 ====================================================*/
.hiw-bottom-left-round {
  border-bottom-left-radius: 600px;
}

.hiw-bottom-right-round {
  border-bottom-right-radius: 600px;
}

.add-business-item {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -15%;
  width: 33%;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px dashed #e4e4e4;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .add-business-item {
    width: 50%;
    bottom: -10%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .add-business-item {
    position: inherit;
    top: auto;
    bottom: auto;
    left: auto;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    width: 62%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 480px) {
  .add-business-item {
    position: inherit;
    top: auto;
    bottom: auto;
    left: auto;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.add-business-item a {
  display: block;
  width: 100%;
  padding: 35px 20px 27px 20px;
}
.add-business-item a span {
  font-size: 30px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  margin-bottom: 20px;
  background-color: rgba(128, 137, 150, 0.2);
  color: #808996;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.add-business-item a p {
  color: #808996;
  font-size: 16px;
  font-weight: 500;
}
.add-business-item:hover {
  -webkit-transform: translateX(-50%) scale(1.02);
  -moz-transform: translateX(-50%) scale(1.02);
  -ms-transform: translateX(-50%) scale(1.02);
  -o-transform: translateX(-50%) scale(1.02);
  transform: translateX(-50%) scale(1.02);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .add-business-item:hover {
    -webkit-transform: translateX(0) scale(1.02);
    -moz-transform: translateX(0) scale(1.02);
    -ms-transform: translateX(0) scale(1.02);
    -o-transform: translateX(0) scale(1.02);
    transform: translateX(0) scale(1.02);
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .add-business-item:hover {
    -webkit-transform: translateX(0) scale(1.02);
    -moz-transform: translateX(0) scale(1.02);
    -ms-transform: translateX(0) scale(1.02);
    -o-transform: translateX(0) scale(1.02);
    transform: translateX(0) scale(1.02);
  }
}

/*======= video-box ========*/
.video-box {
  position: relative;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
  display: block;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  color: #fff;
}
.video-box img {
  width: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.video-box .video-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.video-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333f57;
  opacity: 0.5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.video-box:hover {
  color: #fff;
}

.video-box-negative {
  margin-bottom: -40px;
}

.dot-bg {
  position: relative;
}
.dot-bg:before {
  position: absolute;
  content: "";
  bottom: -40px;
  right: -41px;
  width: 45%;
  height: 45%;
  background-image: url("../images/dots.png");
  z-index: -1;
  background-size: cover;
  background-position: center;
  opacity: 0.1;
}
@media (max-width: 425px) {
  .dot-bg:before {
    right: auto;
    left: -36px;
    bottom: -18px;
    width: 60%;
  }
}

/* hiw-info-box-wrap */
.hiw-info-box-wrap {
  position: relative;
  bottom: -95px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-video-btn-box {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-video-btn-box {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .single-video-btn-box {
    margin-top: 30px;
  }
}

/*===== card item =====*/
.card-item {
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.card-item .card-image {
  position: relative;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}
.card-item .card-image .card__img {
  width: 100%;
  height: 15rem;
  object-fit: cover;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}
.card-item .card-image .badge {
  position: absolute;
  background-color: #40cc6f;
  top: 15px;
  left: 21px;
  padding: 6px 11px;
  font-size: 12px;
  text-transform: capitalize;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: 600;
  z-index: 1;
  color: #fff;
}
.card-item .card-image .badge-transparent {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.card-item .card-image .post-share,
.card-item .card-image .bookmark-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
  font-size: 18px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.card-item .card-image .price-range {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
  padding: 2px 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.card-item .card-image .post-share {
  color: #333f57;
  background-color: #fff;
}
.card-item .card-image .post-share:hover {
  color: #7b68ee;
}
.card-item .card-image .bookmark-btn {
  background-color: rgba(39, 43, 65, 0.6);
  color: #fff;
}
.card-item .card-image .bookmark-btn:hover {
  background-color: #7b68ee;
}
.card-item .card-image .bookmark-btn.active .la-bookmark:before {
  /* content: '\f00c'; */
}
.card-item .card-image .post-share-social {
  padding-left: 2px;
  -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  visibility: hidden;
}
.card-item .card-image .post-share-social .post-share-social-icon {
  display: block;
  color: #333f57;
  background-color: #fff;
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-top: 3px;
  -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}
.card-item .card-image .post-share-social .post-share-social-icon:nth-child(1) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.card-item .card-image .post-share-social .post-share-social-icon:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.card-item .card-image .post-share-social .post-share-social-icon:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.card-item .card-image .post-share-social .post-share-social-icon:hover {
  color: #7b68ee;
}
.card-item .card-image .post-share:hover .post-share-social,
.card-item .card-image .post-share:hover .post-share-social-icon {
  opacity: 1;
  visibility: visible;
}
.card-item .card-image .post-share:hover .post-share-social-icon {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.card-item .card-image:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #333f57;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.card-item .card-content {
  padding: 30px 20px 25px 20px;
  position: relative;
}
.card-item .card-content .user-thumb {
  position: absolute;
  left: 20px;
  top: -28px;
  z-index: 1;
}
.card-item .card-title {
  font-size: 20px;
  color: #333f57;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.card-item .card-title a {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.card-item .card-title a:hover {
  color: #7b68ee;
}
.card-item .card-title i {
  color: #40cc6f;
}
.card-item .card-sub {
  font-size: 16px;
  color: #808996;
  font-weight: 500;
  line-height: 26px;
}
.card-item .card-sub a {
  color: #808996;
}
.card-item .listing-meta {
  padding-top: 15px;
}
@media (max-width: 1199px) {
  .card-item .listing-meta {
    flex-wrap: wrap;
  }
}
.card-item .listing-meta .rate {
  background-color: #40cc6f;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 35px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-right: 7px;
  font-weight: 600;
}
.card-item .listing-meta .listing-cat-link {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
}
.card-item .listing-meta .listing-cat-link:hover {
  color: #7b68ee;
}
.card-item .listing-meta .listing-icon {
  font-size: 20px;
  color: #40cc6f;
}
.card-item .listing-meta .listing--icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.card-item .listing-meta .listing-rate-count span {
  font-size: 14px;
}
.card-item .listing-meta .listing-star {
  color: #7b68ee;
  line-height: 16px;
}
.card-item .listing-meta .listing-star i + i {
  margin-left: -4px;
}
.card-item .listing-meta .price-range {
  border-left: 1px solid rgba(128, 137, 150, 0.1);
  border-right: 1px solid rgba(128, 137, 150, 0.1);
  padding-left: 15px;
  margin-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  display: inline-block;
}
.card-item:hover {
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
}
.card-item:hover .card-image:after {
  opacity: 0.2;
}

.small-wave-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  fill: #fff;
  stroke: none;
  z-index: 1;
}

/*==== card-item-list ====*/
.card-item-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-list {
    flex-direction: column;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-list {
    flex-direction: column;
  }
}
.card-item-list .card-image {
  width: 43%;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-list .card-image {
    width: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-list .card-image {
    width: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.card-item-list .card-image .card__img {
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  object-fit: cover;
  height: 313px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-list .card-image .card__img {
    height: auto;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-list .card-image .card__img {
    height: auto;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.card-item-list .card-image:after {
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-list .card-image:after {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-list .card-image:after {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.card-item-list .card-content {
  width: 57%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-list .card-content {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-list .card-content {
    width: 100%;
  }
}
.card-item-list .card-content .user-thumb {
  position: inherit;
  top: auto;
  left: auto;
}

/*==== card-item--list ====*/
.card-item--list .card-image {
  width: 30%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item--list .card-image {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item--list .card-image {
    width: 100%;
  }
}

.card-item--list .card-content {
  width: 70%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item--list .card-content {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item--list .card-content {
    width: 100%;
  }
}

.card-item--list:hover {
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
}

/*======= flip-box-item ==========*/
.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.flip-box .flip-img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  width: 100%;
}
.flip-box .flip-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  -webkit-transform: translateZ(70px) scale(0.9);
  -moz-transform: translateZ(70px) scale(0.9);
  -ms-transform: translateZ(70px) scale(0.9);
  -o-transform: translateZ(70px) scale(0.9);
  transform: translateZ(70px) scale(0.9);
  color: #fff;
}
.flip-box .flip-title {
  color: #fff;
  font-size: 20px;
}
.flip-box .flip-subtitle {
  font-weight: 600;
}
.flip-box:hover .flip-box-front {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-box:hover .flip-box-back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box-front,
.flip-box-back {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
}
.flip-box-front:after,
.flip-box-back:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #333f57;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  opacity: 0.7;
}

.flip-box-front {
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/*==== card-image-shape ====*/
.card-image-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  width: 100%;
}

/*==== pill ====*/
.pill {
  background-color: rgba(128, 137, 150, 0.1);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 2px 12px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
  color: #808996;
}
.pill:hover {
  background-color: #7b68ee;
  color: #fff;
}

/*======= card-item-layout-2 ========*/
.card-item-layout-2 {
  text-align: center;
}
.card-item-layout-2 .card-content .user-thumb {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.card-item-layout-2 .listing-meta li {
  font-size: 15px;
}
.card-item-layout-2 .listing-meta li + li {
  border-left: 0;
  padding-left: 0;
  margin-left: 2px;
}

/*======= card-item-layout-3 ========*/
.card-item-layout-3 {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 {
    flex-direction: column;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 {
    flex-direction: column;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 {
    flex-direction: column;
  }
}
.card-item-layout-3 .video-box {
  margin-bottom: 0;
  z-index: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.card-item-layout-3 .card-image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
  }
}
.card-item-layout-3 .card-image .card__img {
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
  object-fit: cover;
  height: 381px;
}
@media (max-width: 1199px) {
  .card-item-layout-3 .card-image .card__img {
    height: 460px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image .card__img {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    height: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image .card__img {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    height: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image .card__img {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    height: auto;
  }
}
.card-item-layout-3 .card-image .shop-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
.card-item-layout-3 .card-image:after {
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image:after {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image:after {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image:after {
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
  }
}
.card-item-layout-3 .card-image-lg .card__img {
  height: 444px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image-lg .card__img {
    height: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image-lg .card__img {
    height: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image-lg .card__img {
    height: auto;
  }
}
.card-item-layout-3 .card-image-round-right {
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image-round-right {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image-round-right {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image-round-right {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
.card-item-layout-3 .card-image-round-right .card__img {
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image-round-right .card__img {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image-round-right .card__img {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image-round-right .card__img {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
.card-item-layout-3 .card-image-round-right:after {
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-item-layout-3 .card-image-round-right:after {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-image-round-right:after {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-image-round-right:after {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
  }
}
.card-item-layout-3 .card-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  padding: 50px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card-item-layout-3 .card-content {
    padding: 30px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .card-item-layout-3 .card-content {
    padding: 30px;
  }
}

/*======= card-item-layout-4 ========*/
.card-item-layout-4 {
  position: relative;
}
.card-item-layout-4 .card-image {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.card-item-layout-4 .card-image .card__img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.card-item-layout-4 .card-image:after {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  opacity: 0.8;
}
.card-item-layout-4 .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.card-item-layout-4 .card-title {
  color: #fff;
}
.card-item-layout-4 .card-title a {
  color: #fff;
}
.card-item-layout-4 .info-list li {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.card-item-layout-4 .info-list li:hover a {
  color: #fff;
}
.card-item-layout-4 .info-list a {
  color: #fff;
}
.card-item-layout-4:hover {
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
}

/*======= card-item-layout-5 ========*/
.card-item-layout-5 {
  height: 380px;
}
.card-item-layout-5 .card-image {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 100%;
}
.card-item-layout-5 .card-image .card__img {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
}
.card-item-layout-5 .card-image:after {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  opacity: 0.8;
}
.card-item-layout-5 .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.card-item-layout-5 .card-content .user-thumb {
  position: inherit;
  top: auto;
  left: auto;
  display: inline-block;
}
.card-item-layout-5 .card-title {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}
.card-item-layout-5 .card-title a {
  color: #fff;
}
.card-item-layout-5 .card-title a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.card-item-layout-5 .listing-meta li {
  color: #fff;
}
.card-item-layout-5 .listing-meta li .listing-cat-link {
  color: #fff;
}
.card-item-layout-5 .listing-meta li .listing-cat-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.card-item-layout-5:hover .card-image:after {
  opacity: 0.6;
}

/*======== single-card =========*/
.single-card .card-content .user-thumb {
  position: inherit;
  top: auto;
  left: auto;
}

.single-card:hover {
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
}

/*======== mini-list-card =========*/
.mini-list-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.mini-list-card + .mini-list-card {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(128, 137, 150, 0.1);
}
.mini-list-card .mini-list-img {
  margin-right: 13px;
}
.mini-list-card .mini-list-img img {
  width: 80px;
  height: 85px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.mini-list-card .mini-list-title {
  font-size: 16px;
  color: #333f57;
  font-weight: 600;
  line-height: 20px;
}
.mini-list-card .mini-list-title a {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.mini-list-card .mini-list-title a:hover {
  color: #7b68ee;
}

/* generic-img-card */
.generic-img-card {
  position: relative;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.generic-img-card .generic-img-card-img {
  width: 100%;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.generic-img-card:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333f57;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  opacity: 0.7;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.generic-img-card:hover .generic-img-card-img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.generic-img-card:hover .badge:after {
  opacity: 1;
}
.generic-img-card:hover:after {
  opacity: 0.3;
}

/*====== generic-img-card-content =======*/
.generic-img-card-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 10px 20px;
}
.generic-img-card-content .badge {
  background-color: #fff;
  color: #333f57;
  padding: 6px 12px 6px 24px;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.generic-img-card-content .badge:after {
  position: absolute;
  content: "";
  top: 11px;
  left: 12px;
  width: 5px;
  height: 5px;
  background-color: #333f57;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.5;
}
.generic-img-card-content .generic-img-card-counter {
  color: #fff;
  font-weight: 700;
}

/* avatar-photos */
.avatar-photos {
  display: inline-block;
}
.avatar-photos .user-thumb {
  width: 45px;
  height: 45px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.avatar-photos .user-thumb:nth-child(n + 2) {
  margin-left: -15px;
}
.avatar-photos:hover .user-thumb:nth-child(n + 2) {
  margin-left: 0;
}

/*====================================================
    blog-area
 ====================================================*/
.blog-area {
  position: relative;
  z-index: 1;
}

/*====================================================
    funfact-area
 ====================================================*/
.intro-bg {
  background-image: url("../images/funfact-bg.jpg");
  background-size: cover;
  background-position: center;
}

/* counter item */
.counter-item {
  margin-bottom: 30px;
}
.counter-item .counter-icon {
  width: 60px;
  height: 60px;
  line-height: 55px;
}
.counter-item .counter-icon svg {
  width: 30px;
}
.counter-item .counter-content {
  text-align: left;
}
.counter-item .counter__number {
  font-size: 35px;
  font-weight: 700;
}
.counter-item .counter__title {
  font-size: 17px;
  font-weight: 500;
  color: rgba(51, 63, 87, 0.7);
}

/* counter-item-layout-2 */
.counter-item-layout-2 {
  text-align: center;
}
.counter-item-layout-2 .counter-icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  background: linear-gradient(
    to right,
    rgba(102, 170, 255, 0.25),
    rgba(255, 202, 197, 0.25)
  );
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.counter-item-layout-2 .counter-content {
  text-align: center;
}
.counter-item-layout-2 .counter__title {
  font-weight: 600;
}
.counter-item-layout-2:hover .counter-icon {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
}

/*======= countdown-item =======*/
@media (max-width: 425px) {
  .countdown-box {
    padding-bottom: 0;
  }
}

/*======= countdown-item =======*/
.countdown-item {
  margin-right: 60px;
  position: relative;
}
@media (max-width: 425px) {
  .countdown-item {
    width: 50%;
    margin-right: 0;
    margin-bottom: 50px;
  }
}
.countdown-item span {
  display: block;
  color: #fff;
}
.countdown-item .count-text {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  margin-bottom: 30px;
}
.countdown-item #days,
.countdown-item #hours,
.countdown-item #minutes,
.countdown-item #seconds {
  font-size: 70px;
  font-weight: 700;
}
@media (max-width: 600px) {
  .countdown-item #days,
  .countdown-item #hours,
  .countdown-item #minutes,
  .countdown-item #seconds {
    font-size: 30px;
  }
}
.countdown-item:last-child {
  margin-right: 0;
}
.countdown-item:last-child::before {
  display: none;
}
.countdown-item::before {
  position: absolute;
  content: ":";
  top: 51px;
  right: -38px;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
}
@media (max-width: 425px) {
  .countdown-item::before {
    display: none;
  }
}

/*======= hero-category-item ==========*/
.hero-category-item {
  display: block;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  width: 120px;
  text-align: center;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-category-item {
    width: 45%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hero-category-item {
    width: 45%;
  }
}
.hero-category-item a {
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.hero-category-item .icon-element {
  margin-bottom: 10px;
}
.hero-category-item .icon-element:after {
  display: none;
}
.hero-category-item .hero-cat-link {
  padding: 18px 12px 12px 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.hero-category-item .hero-cat-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.hero-category-item .hero-cat-link-2 {
  background-color: rgba(255, 255, 255, 0.1);
}

/* hero-category--item */
.hero-category--item a {
  color: #808996;
}

.hero-category--item .hero-cat-link:hover {
  background-color: rgba(121, 127, 150, 0.1);
  color: #333f57;
}

/* hero-category-item-layout-2 */
.hero-category-item-layout-2 {
  margin-bottom: 30px;
  width: auto;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.hero-category-item-layout-2 a {
  color: #808996;
}
.hero-category-item-layout-2 .hero-cat-link {
  background-color: #fff;
}
.hero-category-item-layout-2 .hero-cat-link:hover {
  background-color: #fff;
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
  color: #333f57;
}

/*======= category-item ==========*/
.category-item {
  position: relative;
  z-index: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
}
.category-item .cat-img {
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.category-item .icon-element {
  background-color: #fff;
  color: #7b68ee;
}
.category-item .icon-element:after {
  display: none;
}
.category-item .category-link {
  text-align: center;
  color: #fff;
  padding-top: 88px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 1199px) {
  .category-item .category-link {
    padding-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-item .category-link {
    padding-top: 132px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .category-item .category-link {
    padding-top: 245px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .category-item .category-link {
    padding-top: 170px;
  }
}
@media (max-width: 375px) {
  .category-item .category-link {
    padding-top: 135px;
  }
}
@media (max-width: 320px) {
  .category-item .category-link {
    padding-top: 100px;
  }
}
.category-item .cat__title {
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
}
.category-item .badge {
  padding: 6px 11px;
  font-weight: 600;
  background-color: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
}
.category-item .category-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.category-item .category-content:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #333f57;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: -1;
}
.category-item:hover .cat-img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.category-item:hover .category-content .badge {
  background-color: #7b68ee;
}
.category-item:hover .category-content .category-link {
  padding-top: 0;
}

/*======= category-item-layout-2 ==========*/
.category-item-layout-2 .category-link {
  padding: 30px;
}

.category-item-layout-2 .cat__title {
  font-size: 18px;
}

.category-item-layout-2:hover .category-content .category-link {
  padding-top: 30px;
}

.category-item-layout-2:hover .cat-img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/*======= category-item-layout--2 ==========*/
.category-item-layout--2 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.category-item-layout--2 .cat-img {
  object-fit: cover;
  height: 280px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.category-item-layout--2 .cat-img-height {
  height: 360px;
}
.category-item-layout--2 .cat-img-height-2 {
  height: 330px;
}
.category-item-layout--2 .category-link {
  color: #333f57;
  padding: 15px;
}
.category-item-layout--2 .cat__title {
  font-size: 18px;
  color: #333f57;
}
.category-item-layout--2 .category-content:after {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.category-item-layout--2 .category-content-inner {
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 10px 20px;
  width: 100%;
}

/*======= category-item-layout-2 ==========*/
.category-item-layout-3 {
  height: 250px;
}
.category-item-layout-3 .cat-img {
  height: 100%;
  object-fit: cover;
}
.category-item-layout-3 .badge {
  opacity: 0;
  visibility: hidden;
}
.category-item-layout-3:hover .badge {
  opacity: 1;
  visibility: visible;
}

/* location-item */
.location-item {
  background-color: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 20px;
  margin-bottom: 30px;
  color: #808996;
  text-transform: capitalize;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.location-item .location-img {
  width: 50px;
  height: 50px;
  border: 3px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
}
.location-item:hover {
  color: #7b68ee;
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
  transform: scale(1.03);
}

/*====================================================
    cta-area
 ====================================================*/
.cta-area {
  background-size: cover;
  background-position: center;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-area .btn-box {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-area .btn-box {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-area .btn-box {
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-area .btn-box.text-right {
    text-align: center !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-area .btn-box.text-right {
    text-align: center !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-area .btn-box.text-right {
    text-align: center !important;
  }
}

.cta-bg {
  background-image: url("../images/cta-bg.jpg");
}

/*====== cta-content-box ======*/
.cta-content-box {
  position: relative;
  z-index: 1;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
}

.cta-content {
  padding: 40px 40px 40px 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-content {
    flex-direction: column;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-content {
    flex-direction: column;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-content {
    flex-direction: column;
  }
}
.cta-content .subscribe-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 320px) {
  .cta-content .subscribe-btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
.cta-content .subscribe-btn:before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 320px) {
  .cta-content .subscribe-btn:before {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-content .form-box {
    margin-top: 20px;
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-content .form-box {
    margin-top: 20px;
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-content .form-box {
    margin-top: 20px;
    width: 100%;
  }
}

.cta-content-top-negative {
  margin-top: -40px;
}

/*==== cta-img ====*/
.cta-img {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
.cta-img img {
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;
  height: 365px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-img img {
    -webkit-border-radius: 8px 8px 50px 50px;
    -moz-border-radius: 8px 8px 50px 50px;
    border-radius: 8px 8px 50px 50px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-img img {
    -webkit-border-radius: 8px 8px 50px 50px;
    -moz-border-radius: 8px 8px 50px 50px;
    border-radius: 8px 8px 50px 50px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-img img {
    -webkit-border-radius: 8px 8px 50px 50px;
    -moz-border-radius: 8px 8px 50px 50px;
    border-radius: 8px 8px 50px 50px;
  }
}
.cta-img .cta-img-shape {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-img .cta-img-shape {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .cta-img .cta-img-shape {
    display: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .cta-img .cta-img-shape {
    display: none;
  }
}
.cta-img .cta-img-shape svg {
  width: 100%;
  height: 100%;
}

/*==== cta-content-box-2 ====*/
.cta-content-box-2 {
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.cta-content-box-2 .cta-img {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.cta-content-box-2 .cta-img img {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*===== mobile area =====*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-app-content {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .mobile-app-content {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .mobile-app-content {
    margin-bottom: 50px;
  }
}

.mobile-app-content .info-list li {
  color: #333f57;
  font-weight: 600;
}
.mobile-app-content .info-list li .icon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 20px;
}

.mobile-img {
  position: relative;
}

.map-marker {
  position: absolute;
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  padding: 4px;
}
@media (max-width: 480px) {
  .map-marker {
    width: 40px;
    height: 40px;
  }
}
.map-marker img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.map-marker:after {
  top: 67px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border: solid transparent;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}
@media (max-width: 480px) {
  .map-marker:after {
    top: 40px;
  }
}

.map-marker-1 {
  left: 50%;
  top: 30%;
}

.map-marker-2 {
  left: 38%;
  bottom: 22%;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.map-marker-3 {
  right: 15%;
  bottom: 55%;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.map-marker-4 {
  left: 12%;
  top: 30%;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.map-marker-5 {
  left: 30%;
  top: 19%;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.map-marker-6 {
  right: 35%;
  top: -4%;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.map-pin-bg {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) rotateX(55deg);
  -moz-transform: translateX(-50%) rotateX(55deg);
  -ms-transform: translateX(-50%) rotateX(55deg);
  -o-transform: translateX(-50%) rotateX(55deg);
  transform: translateX(-50%) rotateX(55deg);
  top: 74px;
}
@media (max-width: 480px) {
  .map-pin-bg {
    top: 44px;
  }
}

/*======== dashboard-wrap =======*/
@media (max-width: 1199px) {
  .dashboard-wrap {
    flex-direction: column;
  }
}

/*======== dashboard-sidebar =======*/
.dashboard-sidebar {
  min-height: 100vh;
  width: 20rem;
  background-color: #fff;
  -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  -moz-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  z-index: 1024;
  position: fixed;
  left: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 1199px) {
  .dashboard-sidebar {
    left: -20rem;
  }
}
.dashboard-sidebar .sidebar-brand {
  display: block;
  padding: 20px;
  z-index: 1;
  background-color: #4c60da;
}
.dashboard-sidebar .nav-item {
  position: relative;
}
.dashboard-sidebar .nav-item.active .nav-link {
  color: #333f57;
  background-color: rgba(128, 137, 150, 0.1);
  border-left-color: #333f57;
}
.dashboard-sidebar .nav-link {
  display: block;
  padding: 10px 16px;
  color: #808996;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-left: 3px solid transparent;
  position: relative;
  font-size: 15px;
}
.dashboard-sidebar .nav-link .badge {
  position: absolute;
  top: 13px;
  right: 20px;
}
.dashboard-sidebar .nav-link:hover {
  background-color: rgba(128, 137, 150, 0.1);
}
.dashboard-sidebar .sidebar-heading {
  padding: 0 21px;
  font-weight: 700;
  font-size: 0.65rem;
  letter-spacing: 0.13rem;
  text-transform: uppercase;
}
.dashboard-sidebar.sidebar-is-active {
  left: 0;
}

/*======== dashboard-body =======*/
.dashboard-body {
  background-color: #fafdfb;
  overflow-x: hidden;
  width: calc(100% - 20rem);
  margin-left: auto;
}
@media (max-width: 1199px) {
  .dashboard-body {
    width: 100%;
  }
}

/*======= dashboard-topbar =======*/
.bg-navbar {
  background-color: #6777ef;
}

#sidebar-close,
#sidebarToggleTop {
  height: 40px;
  width: 40px;
  line-height: 34px;
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #fff;
  font-size: 20px;
  display: none;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media (max-width: 1199px) {
  #sidebar-close,
  #sidebarToggleTop {
    display: block;
  }
}
#sidebar-close:hover,
#sidebarToggleTop:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#sidebar-close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 40px;
}

/*======= dashboard-topbar ========*/
.dashboard-topbar {
  height: 78px;
  z-index: 2;
  -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  -moz-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}
.dashboard-topbar .nav-item.border-left {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .dashboard-topbar .dropdown {
    position: static;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .dashboard-topbar .dropdown {
    position: static;
  }
}
.dashboard-topbar .nav-link {
  color: #fff;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 14px !important;
  font-size: 20px;
  position: relative;
}
.dashboard-topbar .nav-link .badge-counter {
  position: absolute;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  right: 4px;
  margin-top: -10px;
  font-size: 14px;
  color: #fff;
}
.dashboard-topbar .dropdown-menu {
  padding: 0;
  width: 310px;
  -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  -moz-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border-color: rgba(128, 137, 150, 0.1);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .dashboard-topbar .dropdown-menu {
    width: calc(100% - 1.5rem);
    right: 0.75rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .dashboard-topbar .dropdown-menu {
    width: calc(100% - 1.5rem);
    right: 0.75rem;
  }
}
.dashboard-topbar .dropdown-menu-sm {
  width: 185px;
}
.dashboard-topbar .dropdown-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*====== search-box ======*/
.search-box .input-label {
  width: calc(100% - 42px);
}

.search-box .btn-primary {
  background-color: #7b68ee !important;
}

.search-box .form-control {
  font-size: 0.85rem;
  height: 44px;
  font-weight: 500;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 5px solid #7b68ee;
  -webkit-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
}
.search-box .form-control:focus {
  -webkit-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  border-left-color: #533ae9;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .action-buttons {
    position: inherit !important;
    top: auto !important;
    right: auto !important;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .action-buttons {
    position: inherit !important;
    top: auto !important;
    right: auto !important;
    margin-top: 20px;
  }
}

/*====== scrollable-content ======*/
.scrollable-content {
  height: 260px;
  overflow-y: auto;
}

/*====== generic-list-header ======*/
.generic-list-header {
  background-color: #6777ef;
  padding: 12px 20px;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.13rem;
  white-space: nowrap;
}

/*====== generic-list-item ======*/
.generic-list-item {
  white-space: normal;
  padding: 12px 20px;
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  line-height: 1.3rem;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.generic-list-item .icon-element {
  height: 35px;
  width: 35px;
  line-height: 34px;
  font-size: 18px;
}
.generic-list-item .icon-element:after {
  display: none;
}
.generic-list-item .text-truncate {
  max-width: 13.375rem;
}
.generic-list-item:hover {
  background-color: rgba(128, 137, 150, 0.07);
}

/*====== status-indicator======*/
.status-indicator {
  background-color: #e4e4e4;
  height: 0.75rem;
  width: 0.75rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}

/*====== dashboard-booking-list ======*/
.dashboard-booking-list .list--items li span {
  width: 150px;
}

.dashboard-booking-list .generic-list-item {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 480px) {
  .dashboard-booking-list .generic-list-item {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .dashboard-booking-list .generic-list-item .user-thumb {
    margin-bottom: 16px;
  }
}
@media (max-width: 480px) {
  .dashboard-booking-list .generic-list-item .ml-3.flex-grow-1 {
    margin-left: 0 !important;
  }
}
.dashboard-booking-list .generic-list-item:hover {
  background-color: transparent;
}
.dashboard-booking-list .generic-list-item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

/*====== dashboard-inner-body-container ======*/
.dashboard-inner-body-container {
  padding-right: 27px;
  padding-left: 27px;
}

.dashboard-footer {
  padding: 18px 15px;
  -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  -moz-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}
.dashboard-footer .list-items li {
  font-size: 14px;
  margin-bottom: 0;
}

/*====== dashboard-stat ======*/
.dashboard-stat {
  -webkit-box-shadow: 0 0px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0px 40px rgba(82, 85, 90, 0.1);
}
.dashboard-stat .card-title {
  opacity: 0.8;
}

.chart-block {
  position: relative;
  width: 100%;
}

#myChartLegend li {
  cursor: pointer;
  background-color: #fff;
  color: #808996;
  padding: 7px 14px;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid rgba(128, 137, 150, 0.2);
  font-weight: 500;
}
#myChartLegend li span {
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 10px;
  margin-right: 8px;
  width: 10px;
  background-color: #e4e4e4;
}
#myChartLegend li:first-child {
  margin-right: 10px;
}
#myChartLegend li:first-child span {
  background-color: #4e73df !important;
}
#myChartLegend li:last-child span {
  background-color: #5ecfb1 !important;
}
#myChartLegend li.legend-active span {
  background-color: #e4e4e4 !important;
}

/*====== dashboard-chart ======*/
.dashboard-card .user-chosen-select-container .chosen-container {
  width: 140px !important;
}

.dashboard-card .user-chosen-select-container .chosen-single {
  padding: 8px 14px;
}
.dashboard-card .user-chosen-select-container .chosen-single div {
  top: 10px;
}

/*====== generic-list-card =======*/
.generic-list-card {
  background-color: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.05);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.05);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.05);
  padding: 20px;
}
.generic-list-card + .generic-list-card {
  margin-top: 10px;
}
.generic-list-card .generic-close {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.generic-list-card .generic-close:hover {
  color: #7b68ee;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*====== generic-list-card-content =======*/
.generic-list-card-content .icon-element {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  -webkit-box-shadow: 0 9px 16px rgba(58, 87, 135, 0.1);
  -moz-box-shadow: 0 9px 16px rgba(58, 87, 135, 0.1);
  box-shadow: 0 9px 16px rgba(58, 87, 135, 0.1);
  flex-shrink: 0;
}

.generic-list-card-content .generic-link {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
}
.generic-list-card-content .generic-link:hover {
  color: #7b68ee;
}

/*====== my-table =======*/
.my-table .order-id {
  color: #6777ef;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.my-table .order-id:hover {
  text-decoration: underline;
  color: #4c60da;
}

.my-table .table {
  color: #808996;
  font-weight: 500;
}
.my-table .table td,
.my-table .table th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.my-table .table .thead-light th {
  background-color: rgba(128, 137, 150, 0.07);
  color: #333f57;
  border-top: 0;
  border-bottom: 0;
  font-weight: 600;
}
.my-table .table tbody td {
  vertical-align: middle;
  border-top-color: rgba(128, 137, 150, 0.1);
}

/*====== online-user-slider=======*/
.msg-from-customer .text-truncate {
  max-width: 32.375rem;
}
@media (max-width: 375px) {
  .msg-from-customer .text-truncate {
    max-width: 13.375rem;
  }
}

/*====== online-user-slider=======*/
.online-user-slider .owl-nav div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  background-color: rgba(128, 137, 150, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #808996;
  text-align: center;
  line-height: 23px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 15px;
  opacity: 0;
  visibility: hidden;
}
.online-user-slider .owl-nav div:hover {
  background-color: rgba(128, 137, 150, 0.2);
}
.online-user-slider .owl-nav div.owl-prev {
  left: 4px;
}
.online-user-slider .owl-nav div.owl-next {
  right: 4px;
}

.online-user-slider:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/*====== online-user-box =======*/
.online-user-box .form-control {
  background-color: rgba(128, 137, 150, 0.07);
  border: 0;
}

/*====== online-user-item =======*/
.online-user-item .text-truncate {
  width: 65px;
}

/*====== chat-list =======*/
.chat-list.scrollable-content {
  height: 479px;
}

.chat-list .text-truncate {
  max-width: 15.375rem;
}

/*====== message-body =======*/
.message-body.scrollable-content {
  height: 500px;
}

/*====== message-item =======*/
.message-item.me .generic-list-item {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.message-item.me .message-bubble {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
  background-color: #6777ef;
}
.message-item.me .message-bubble p {
  color: #fff !important;
}
.message-item.me .message-bubble:after {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #6777ef;
  border-right: none;
  right: -6px;
  left: auto;
}

/*====== message-bubble =======*/
.message-bubble {
  background-color: rgba(128, 137, 150, 0.07);
}
.message-bubble:after {
  position: absolute;
  content: "";
  left: -6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid rgba(128, 137, 150, 0.07);
}

.message-reply-body .emojionearea-emojis-list {
  padding-top: 20px;
}

.message-reply-body .emojionearea {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.message-reply-body .emojionearea-search {
  height: auto !important;
}
.message-reply-body .emojionearea-search > input {
  height: auto !important;
  border: 1px solid rgba(128, 137, 150, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 3px 12px;
}

.message-reply-body .emojionearea-editor {
  width: 100%;
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  margin-right: 10px;
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  color: #333f57;
  border: none;
  resize: none;
  outline: none;
}
.message-reply-body .emojionearea-editor:before {
  color: #808996 !important;
}

.message-reply-body .emojionearea-button {
  top: 14px;
}

/*===== message-send ======*/
.message-send {
  width: 35px;
  height: 35px;
  line-height: 30px;
  font-size: 20px;
  cursor: pointer;
  background-color: #6777ef;
}
.message-send .la-paper-plane {
  -webkit-transform: rotate(-42deg);
  -moz-transform: rotate(-42deg);
  -ms-transform: rotate(-42deg);
  -o-transform: rotate(-42deg);
  transform: rotate(-42deg);
}
.message-send:after {
  display: none;
}

/*===== edit-profile-photo ======*/
@media (max-width: 480px) {
  .edit-profile-photo {
    flex-direction: column;
  }
}

.edit-profile-photo .profile-img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /* width: 250px; */
  width: 200px;
  min-height: 220px;
}
@media (max-width: 480px) {
  .edit-profile-photo .profile-img {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .edit-profile-photo .file-upload-wrap {
    margin-left: 0 !important;
    margin-top: 20px;
  }
}

/*====================================================
   clientlogo-area
 ====================================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-box {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-box {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-box {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.client-logo-item {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 10px 25px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 25px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 25px rgba(82, 85, 90, 0.1);
  position: absolute;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item {
    position: inherit;
    margin: 15px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item {
    position: inherit;
    margin: 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item {
    position: inherit;
    margin: 15px;
  }
}
.client-logo-item:nth-child(1) {
  top: 100px;
  left: 0;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(1) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(1) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(1) {
    top: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(2) {
  top: 0;
  left: 15%;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(2) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(2) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(2) {
    top: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(3) {
  top: 25px;
  left: 32%;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(3) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(3) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(3) {
    top: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(4) {
  top: 0;
  left: 50%;
  width: 140px;
  height: 140px;
  line-height: 140px;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(4) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(4) {
    top: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(4) {
    top: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(5) {
  top: 10px;
  right: 20%;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(5) {
    top: auto;
    right: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(5) {
    top: auto;
    right: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(5) {
    top: auto;
    right: auto;
  }
}
.client-logo-item:nth-child(6) {
  top: 10px;
  right: 0;
  width: 150px;
  height: 150px;
  line-height: 150px;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(6) {
    top: auto;
    right: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(6) {
    top: auto;
    right: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(6) {
    top: auto;
    right: auto;
  }
}
.client-logo-item:nth-child(7) {
  bottom: 10px;
  left: 15%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(7) {
    bottom: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(7) {
    bottom: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(7) {
    bottom: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(8) {
  bottom: 10px;
  left: 35%;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(8) {
    bottom: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(8) {
    bottom: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(8) {
    bottom: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(9) {
  bottom: 0;
  left: 56%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(9) {
    bottom: auto;
    left: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(9) {
    bottom: auto;
    left: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(9) {
    bottom: auto;
    left: auto;
  }
}
.client-logo-item:nth-child(10) {
  bottom: 35px;
  right: 15%;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-logo-item:nth-child(10) {
    bottom: auto;
    right: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .client-logo-item:nth-child(10) {
    bottom: auto;
    right: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .client-logo-item:nth-child(10) {
    bottom: auto;
    right: auto;
  }
}
.client-logo-item img {
  width: 50px;
}

/*======== comment ========*/
.comment {
  display: flex;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .comment {
    flex-direction: column;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .comment {
    flex-direction: column;
  }
}

/*======== reviews-list ========*/
.reviews-list .comment:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

/*========= meta-data =========*/
.meta-data {
  position: relative;
}
.meta-data .comment__title {
  font-weight: 600;
  color: #333f57;
  font-size: 18px;
}
.meta-data .comment__title a {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.meta-data .comment__title a:hover {
  color: #7b68ee;
}
.meta-data .comment__meta {
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.meta-data .comment__meta + .comment__meta {
  padding-left: 12px;
  margin-left: 5px;
}
.meta-data .comment__meta + .comment__meta:after {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 4px;
  height: 4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(128, 137, 150, 0.6);
}

/*========= comment-body =========*/
.comment-body {
  margin-left: 18px;
  width: 100%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .comment-body {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .comment-body {
    margin-left: 0;
    margin-top: 15px;
  }
}
.comment-body .comment-desc {
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 20px;
  font-weight: 500;
}

.review-photos a {
  max-width: 23%;
  margin: 0 5px 10px 5px;
}
.review-photos a img {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
}

.review-photos-2 a {
  max-width: 10%;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .review-photos-2 a {
    max-width: 23%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .review-photos-2 a {
    max-width: 23%;
  }
}

/*========= comment-action =========*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .comment-action {
    display: block !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .comment-action {
    display: block !important;
  }
}

/*========= feedback-box =========*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .feedback-box {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .feedback-box {
    margin-top: 20px;
  }
}

.comment-reply {
  margin-left: 95px;
}
@media (max-width: 1199px) {
  .comment-reply {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .comment-reply {
    margin-left: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .comment-reply {
    margin-left: 0;
  }
}

/* info-box */
.info-box {
  background-color: #fff;
  padding: 40px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  z-index: 1;
}
.info-box .info-icon {
  width: 85px;
  height: 85px;
  line-height: 85px;
  -webkit-border-radius: 59% 41% 60% 40%/46% 66% 34% 54%;
  -moz-border-radius: 59% 41% 60% 40%/46% 66% 34% 54%;
  border-radius: 59% 41% 60% 40%/46% 66% 34% 54%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  background-color: #7b68ee;
  color: #fff;
  text-align: center;
  font-size: 36px;
  -webkit-box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  -moz-box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.info-box .info-number {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 33px;
  height: 33px;
  line-height: 30px;
  color: #7b68ee;
  background-color: rgba(123, 104, 238, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
  font-size: 16px;
}
.info-box .info__title {
  font-size: 20px;
  color: #333f57;
  font-weight: 600;
  margin-bottom: 20px;
}
.info-box .info__desc {
  font-size: 16px;
  color: #808996;
  font-weight: 500;
}
.info-box:hover {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
}
.info-box:hover .info-icon,
.info-box:hover .info-number {
  background-color: #533ae9;
}
.info-box:hover .info-number {
  color: #fff;
}

/* info--box */
.info--box {
  border: 1px solid rgba(127, 137, 150, 0.1);
}
.info--box .info-icon {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: linear-gradient(
    to right,
    rgba(255, 201, 196, 0.15),
    rgba(255, 222, 162, 0.15)
  );
  margin-bottom: 25px;
}
.info--box:hover .info-icon {
  background: linear-gradient(
    to right,
    rgba(255, 201, 196, 0.15),
    rgba(255, 222, 162, 0.15)
  );
}

/* info-box-layout-2 */
.info-box-layout-2 .info-icon {
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.info-box-layout-2:hover {
  -webkit-transform: translateY(0) scale(1.02);
  -moz-transform: translateY(0) scale(1.02);
  -ms-transform: translateY(0) scale(1.02);
  -o-transform: translateY(0) scale(1.02);
  transform: translateY(0) scale(1.02);
}

.info-box-layout-2,
.info-box-layout-3 {
  text-align: left;
}
.info-box-layout-2 .info-icon,
.info-box-layout-3 .info-icon {
  margin-left: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  line-height: 65px;
  font-size: 30px;
}

/* info-box-layout-3 */
.info-box-layout-3 {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0 20px 0 0;
}
.info-box-layout-3 .info-icon {
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}
.info-box-layout-3:hover {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.info-box-line-bg .info-box:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 180px;
  height: 68px;
  background-image: url("../images/dashed-line.png");
  background-repeat: no-repeat;
  opacity: 0.1;
}

.info-box-line-bg .col-lg-4:nth-child(2) .info-box:after {
  background-image: url("../images/dashed-line-2.png");
}

.info-box-line-bg .col-lg-4:last-child .info-box:after {
  display: none;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .review-content {
    display: block !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .review-content {
    display: block !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .review-bars {
    flex-direction: column;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .review-bars {
    flex-direction: column;
  }
}

/*======== review-rating-summary =========*/
.review-rating-summary {
  width: 140px;
  text-align: center;
  border-right: 1px solid rgba(128, 137, 150, 0.1);
  padding-right: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .review-rating-summary {
    width: auto;
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .review-rating-summary {
    width: auto;
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }
}

/*======== stats-average__count =========*/
.stats-average__count {
  color: #333f57;
  font-size: 40px;
  font-weight: 600;
}

/*======== review-bars-item =========*/
.review-bars-item {
  margin: 10px 15px;
  width: calc(50% - 30px);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .review-bars-item {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .review-bars-item {
    width: 100%;
  }
}
.review-bars-item .review-bars-name {
  font-weight: 500;
  color: #333f57;
  display: block;
  font-size: 15px;
  line-height: 22px;
}
.review-bars-item .review-bars-review {
  display: inline-block;
  width: 100%;
  height: 6px;
  margin-right: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #eee;
  overflow: hidden;
}
.review-bars-item .review-bars-review.high .review-bars-review-inner {
  background-color: #40cc6f;
}
.review-bars-item .review-bars-review.mid .review-bars-review-inner {
  background-color: #f9b851;
}
.review-bars-item .review-bars-review.low .review-bars-review-inner {
  background-color: #daca58;
}
.review-bars-item .review-bars-review-inner {
  height: 6px;
  display: block;
  background-color: #eee;
  position: relative;
  width: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.review-bars-item .pill {
  background-color: #eee;
  font-size: 14px;
  color: #333f57;
  line-height: 20px;
  padding: 1px 10px;
}

/*====== leave-rating ========*/
.leave-rating {
  height: 24px;
  float: left;
}
.leave-rating input[type="radio"] {
  display: none;
}
.leave-rating input[type="radio"]:hover ~ label:before,
.leave-rating input[type="radio"]:checked ~ label:before {
  color: #f9b851;
}
.leave-rating label {
  font-size: 20px;
  float: right;
  letter-spacing: 4px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.leave-rating label:before {
  content: "\f005";
  color: #e4e4e4;
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.add-rating-bars .review-bars-item {
  width: calc(29% - 30px);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .add-rating-bars .review-bars-item {
    width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .add-rating-bars .review-bars-item {
    width: 100%;
  }
}

/* section-tab */
.section-tab .nav-tabs {
  border-bottom: none;
}

.section-tab .nav-item {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: 600;
  font-size: 18px;
}

.section-tab .nav-link {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 15px 30px;
  border: none;
  color: #808996;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-tab .nav-link {
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .section-tab .nav-link {
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .section-tab .nav-link {
    margin-bottom: 5px;
  }
}
.section-tab .nav-link.active {
  background-color: #fff;
  color: #7b68ee;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.2);
}
.section-tab .nav-link.nav-link-gradient.active {
  background: linear-gradient(270deg, #9f8fff, #7b68ee);
  -webkit-box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  -moz-box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  box-shadow: 0 5px 25px rgba(123, 104, 238, 0.3);
  color: #fff;
}

/* section--tab */
.section--tab .nav-link {
  color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
  border-radius: 12px 12px 0 0;
  padding-top: 12px;
  padding-bottom: 12px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section--tab .nav-link {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .section--tab .nav-link {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .section--tab .nav-link {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
.section--tab .nav-link.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* section-tab-layout-2 */
.section-tab-layout-2 .nav-link {
  padding: 7px 16px 7px 30px;
  position: relative;
  font-size: 17px;
}
.section-tab-layout-2 .nav-link:after {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 13px;
  width: 8px;
  height: 8px;
  background-color: #333f57;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.section-tab-layout-2 .nav-link.active {
  color: #333f57;
}
.section-tab-layout-2 .nav-link.active:after {
  opacity: 1;
}

#particles-js {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/*======= file upload ========*/
.file-upload-wrap {
  position: relative;
}
.file-upload-wrap .file-upload-input {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  border: 2px dashed rgba(128, 137, 150, 0.2);
  height: 170px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-indent: -99999px;
}
.file-upload-wrap .file-upload-input:hover {
  border-color: rgba(128, 137, 150, 0.6);
  background-color: rgba(128, 137, 150, 0.04);
}
.file-upload-wrap .file-upload-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  line-height: 170px;
  text-align: center;
  font-size: 20px;
  color: rgba(128, 137, 150, 0.9);
  font-weight: 500;
}
.file-upload-wrap .MultiFile-list {
  margin-top: 20px;
}
.file-upload-wrap .MultiFile-list > .MultiFile-label {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: rgba(128, 137, 150, 0.06);
  display: inline-block;
  border: 2px solid rgba(128, 137, 150, 0.1);
  padding: 16px;
  position: relative;
  width: 100%;
}
.file-upload-wrap .MultiFile-list > .MultiFile-label + .MultiFile-label {
  margin-top: 20px;
}
.file-upload-wrap .MultiFile-list > .MultiFile-label > span {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.file-upload-wrap .MultiFile-list span.MultiFile-label {
  box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.08);
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 10px;
}
.file-upload-wrap .MultiFile-list .MultiFile-remove {
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  color: #333f57;
  width: 30px;
  height: 30px;
  text-align: center;
  top: -16px;
  right: -16px;
  z-index: 1;
}
.file-upload-wrap .MultiFile-list .MultiFile-remove:hover {
  color: #e62117;
}
.file-upload-wrap .MultiFile-list .MultiFile-title {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 16px;
  bottom: 0;
  text-align: center;
  width: 100%;
  line-height: 24px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.file-upload-wrap .MultiFile-list .MultiFile-preview {
  max-width: 15rem !important;
  max-height: 15rem !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*======== file-upload-wrap-2 =======*/
.file-upload-wrap-2 .file-upload-input {
  width: 175px;
  border: 1px solid rgba(128, 137, 150, 0.1);
  height: 50px;
  background-color: rgba(128, 137, 150, 0.04);
}

.file-upload-wrap-2 .file-upload-text {
  width: auto;
  left: 20px;
  line-height: 50px;
  font-size: 13px;
}

/*======== file-upload-wrap-3 =======*/
.file-upload-wrap-3 .file-upload-input {
  width: 30px;
  border: 0;
  height: 25px;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.file-upload-wrap-3 .file-upload-input:hover {
  background-color: transparent;
}

.file-upload-wrap-3 .file-upload-text {
  line-height: 25px;
  font-size: 20px;
}

.file-upload-wrap-3 .MultiFile-list {
  position: absolute;
  right: 0;
  width: 1000px;
  bottom: 55px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .file-upload-wrap-3 .MultiFile-list {
    width: 310px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .file-upload-wrap-3 .MultiFile-list {
    width: 310px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .file-upload-wrap-3 .MultiFile-list {
    width: 310px;
  }
}
.file-upload-wrap-3 .MultiFile-list > .MultiFile-label {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
}
.file-upload-wrap-3 .MultiFile-list .MultiFile-preview {
  max-width: 10rem !important;
  max-height: 10rem !important;
}

/*======== file-upload-wrap-3 =======*/
.file-upload-wrap-4 .file-upload-input {
  height: 100px;
}

.file-upload-wrap-4 .file-upload-text {
  line-height: 100px;
  font-size: 16px;
}

/*====== edit-profile-photo =======*/
.edit-profile-photo .file-upload-wrap .MultiFile-list .MultiFile-preview {
  max-width: 10rem !important;
  max-height: 10rem !important;
}

/*====================================================
    testimonial-area
 ====================================================*/
.testimonial-area {
  position: relative;
  z-index: 1;
}

/*======= testimonial-carousel ========*/
.testimonial-carousel {
  position: relative;
}
.testimonial-carousel .owl-stage-outer {
  padding: 40px 0;
}
.testimonial-carousel .owl-item.active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.testimonial-carousel .owl-item.active.center {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.testimonial-carousel .testimonial-item .testi__img {
  width: 90px;
}

/*========= testimonial-item =========*/
.testimonial-item .testi__img {
  width: 90px;
  height: 90px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.2);
}
.testimonial-item .testi__img img {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.testimonial-item .testi-comment {
  background-color: #fff;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.05);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.05);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.05);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  position: relative;
  padding: 50px 30px 33px 30px;
  margin-bottom: 42px;
  z-index: 1;
  text-align: center;
}
.testimonial-item .testi-comment .testi__desc {
  font-size: 19px;
  color: #808996;
  line-height: 31px;
  font-weight: 500;
}
.testimonial-item .testi-comment:before {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-right: 1px solid rgba(128, 137, 150, 0.1);
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
}
.testimonial-item .testi-comment:after {
  position: absolute;
  content: "\f10d";
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #7b68ee;
  z-index: -1;
  font-size: 35px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 10px 40px rgba(82, 85, 90, 0.1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgba(128, 137, 150, 0.1);
}

.testimonial-item .tesi__title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 17px;
  margin-bottom: 15px;
  color: #333f57;
}

.testimonial-item .testi__meta {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  color: #7b68ee;
  background-color: rgba(123, 104, 238, 0.05);
  padding: 6px 17px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/*===== testimonial-area2 ======*/
.testimonial-area2 {
  padding-top: 173px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .testimonial-area2 {
    padding-top: 96px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .testimonial-area2 {
    padding-top: 96px;
  }
}

/*====================================================
    author-area
 ====================================================*/
.user-thumb {
  width: 55px;
  height: 55px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0 4px 40px rgba(82, 85, 90, 0.2);
  -moz-box-shadow: 0 4px 40px rgba(82, 85, 90, 0.2);
  box-shadow: 0 4px 40px rgba(82, 85, 90, 0.2);
}
.user-thumb img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.user-thumb-xl {
  width: 132px;
  height: 132px;
}

.user-thumb-lg {
  width: 80px;
  height: 80px;
}

.user-thumb-md {
  width: 65px;
  height: 65px;
}

.user-thumb-sm {
  width: 40px;
  height: 40px;
}

/*==== user-card ====*/
.user-card {
  background-color: #fff;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 30px 20px 30px 20px;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
}
.user-card:hover {
  -webkit-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
}

.user-bio {
  position: relative;
}
.user-bio .author__title {
  text-transform: capitalize;
  color: #333f57;
  font-weight: 600;
  font-size: 20px;
}
.user-bio .author__meta {
  font-weight: 500;
  color: #808996;
}

.user-module-list {
  border-top: 1px solid rgba(127, 137, 150, 0.1);
  border-bottom: 1px solid rgba(127, 137, 150, 0.1);
}
.user-module-list li {
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
}
.user-module-list li + li {
  border-left: 1px solid rgba(127, 137, 150, 0.1);
}
.user-module-list li .user-module-text {
  display: block;
  color: #333f57;
}

/*====================================================
    user-profile-area
 ====================================================*/
.user-content {
  border: 1px solid rgba(128, 137, 150, 0.2);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.04);
  padding: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .user-content {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .user-content {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .user-content {
    margin-bottom: 50px;
  }
}
.user-content .user-item .user__label {
  font-size: 16px;
  color: #808996;
  font-weight: 500;
  margin-bottom: 5px;
}
.user-content .user-item .userlist__number {
  font-size: 18px;
  font-weight: 700;
  color: #333f57;
}
.user-content .user-details .author-review {
  margin-right: 15px;
  border-right: 1px solid #ddd;
  padding-right: 15px;
}

/* map-container */
.map-container {
  position: relative;
  overflow: hidden;
}

/* enable-scroll */
.enable-scroll {
  position: absolute;
  bottom: 24px;
  right: 60px;
  z-index: 20;
  padding: 8px 16px;
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #808996;
  font-size: 15px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
}
.enable-scroll.enabled {
  background-color: #7b68ee;
  color: #fff;
}
.enable-scroll.enabled:hover {
  color: #fff;
}
.enable-scroll:hover {
  color: #333f57;
}

/* map-wrap */
#myMap,
#map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.height-400 {
  height: 400px;
}

.height-500 {
  height: 500px;
}

.height-980 {
  height: 980px;
}

/* full-screen-container */
@media (max-width: 1199px) {
  .full-screen-container {
    flex-direction: column;
  }
}

@media (max-width: 1199px) {
  .full-screen-container .card-area {
    width: 100% !important;
  }
}

.full-screen-container .home-map {
  position: sticky;
  top: 0;
  height: 100vh;
}
@media (max-width: 1199px) {
  .full-screen-container .home-map {
    width: 100% !important;
    position: inherit;
    top: auto;
    height: auto;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .full-screen-container .price-range-wrap.ml-3 {
    margin-left: 0 !important;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .full-screen-container .price-range-wrap.ml-3 {
    margin-left: 0 !important;
    margin-top: 15px;
  }
}

/* map-info-popup */
.map-info-popup {
  display: block;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.map-info-popup:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #fff;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

/* item-popup-box */
.item-popup-box {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 0px 9px rgba(0, 0, 0, 0.01);
  -moz-box-shadow: 0 0px 9px rgba(0, 0, 0, 0.01);
  box-shadow: 0 0px 9px rgba(0, 0, 0, 0.01);
}
.item-popup-box img {
  width: 100%;
  height: auto;
  -webkit-border-radius: 8px 8px 14px 14px;
  -moz-border-radius: 8px 8px 14px 14px;
  border-radius: 8px 8px 14px 14px;
}
.item-popup-box .close-info {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 31px;
  top: 20px;
  text-align: center;
  right: 20px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 20;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.item-popup-box .close-info .la-close {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 700;
}
.item-popup-box .close-info:hover {
  background-color: #fff;
}
.item-popup-box .close-info:hover .la-close {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #7b68ee;
}
.item-popup-box .map-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  font-weight: 500;
  color: #333f57;
  z-index: 20;
  padding: 6px 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.item-popup-box .map-badge:hover {
  color: #7b68ee;
}
.item-popup-box .map-img-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #333f57;
  opacity: 0.4;
  -webkit-border-radius: 4px 4px 12px 12px;
  -moz-border-radius: 4px 4px 12px 12px;
  border-radius: 4px 4px 12px 12px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.item-popup-box .item-list-content {
  background-color: #fff;
  padding: 25px 20px;
  z-index: 20;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.item-popup-box .item-list-content .item-ratting {
  font-size: 16px;
}
.item-popup-box .item-list-content .item-ratting i {
  color: #f9b851;
  margin-right: 3px;
}
.item-popup-box .item-list-content .item-ratting .map-review-count {
  font-size: 15px;
}
.item-popup-box .item-list-content h4 {
  font-size: 17px;
  font-weight: 600;
  color: #333f57;
}
.item-popup-box .item-list-content h4 a {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.item-popup-box .item-list-content h4 a:hover {
  color: #7b68ee;
}
.item-popup-box .item-list-content .item-call,
.item-popup-box .item-list-content .location-info {
  color: #808996;
  font-size: 15px;
}
.item-popup-box .item-list-content .item-call a,
.item-popup-box .item-list-content .location-info a {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.item-popup-box .item-list-content .item-call a:hover,
.item-popup-box .item-list-content .location-info a:hover {
  color: #7b68ee;
}
.item-popup-box .item-list-content .item-call i,
.item-popup-box .item-list-content .location-info i {
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(123, 104, 238, 0.1);
  margin-right: 8px;
  color: #7b68ee;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.item-popup-box .item-list-content .item-call:hover i,
.item-popup-box .item-list-content .location-info:hover i {
  background-color: #7b68ee;
  color: #fff;
}
.item-popup-box:hover .map-img-box:before {
  opacity: 0;
}

/*== general map style ===*/
.cluster {
  width: 40px !important;
  height: 40px !important;
}
.cluster div {
  text-align: center;
  font-size: 16px !important;
  background: #333f57;
  color: #fff;
  font-weight: 600;
  border-radius: 100%;
  width: 40px !important;
  height: 40px !important;
  line-height: 38px !important;
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
  border: 2px solid #333f57;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  animation: cluster-animation 1.5s infinite;
}
.cluster div:hover {
  background-color: #808996;
}

/*===== tag-list =====*/
.tag-list li {
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 7px;
  font-weight: 500;
  margin-right: 4px;
}
.tag-list li a {
  background-color: #fff;
  color: #808996;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 6px 15px 6px 24px;
  display: block;
  -webkit-box-shadow: 0 0 20px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 20px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 20px rgba(82, 85, 90, 0.1);
  position: relative;
}
.tag-list li a:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 12px;
  width: 6px;
  height: 6px;
  background-color: rgba(51, 63, 87, 0.2);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.tag-list li:hover a {
  color: #333f57;
}
.tag-list li:hover a:after {
  background-color: #333f57;
}

/*===== tag-list-sm =====*/
.tag-list-sm li {
  font-size: 14px;
}
.tag-list-sm li a {
  padding: 2px 14px 2px 24px;
}

/*===== tag-items =====*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .tag-items {
    display: block !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .tag-items {
    display: block !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .tag-items .tag-list {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .tag-items .tag-list {
    margin-bottom: 15px;
  }
}

/*========= widget title ========*/
.widget-title {
  font-size: 18px;
  color: #333f57;
  font-weight: 600;
  padding-bottom: 15px;
  position: relative;
}
@media (max-width: 320px) {
  .widget-title {
    font-size: 20px;
  }
}

/*====== sidebar =====*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .sidebar {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .sidebar {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

/*======== sidebar-widget =======*/
.sidebar-widget {
  margin-bottom: 30px;
  border: 1px solid rgba(128, 137, 150, 0.1);
  padding: 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  background-color: #fff;
  position: relative;
}

.twitter-bg-icon {
  position: absolute;
  right: -25px;
  bottom: -30px;
}
.twitter-bg-icon svg {
  width: 100px;
  height: 100px;
  fill: #71c9f8;
}

/*====================================================
    footer-area
 ====================================================*/
.footer-bg {
  position: relative;
  z-index: 1;
}
.footer-bg:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bg1.png");
  background-size: cover;
  background-position: center;
  opacity: 0.1;
}

/* footer item */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-item {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-item {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .footer-item {
    margin-bottom: 40px;
  }
}

.footer-item .footer__desc {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  line-height: 27px;
}
.footer-item .footer__desc a {
  color: #333f57;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.footer-item .footer__desc a:hover {
  color: #7b68ee;
}

.footer-item .social-profile {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-item .social-profile {
    margin-top: 10px;
  }
}

.footer-item .footer__title {
  font-size: 17px;
  font-weight: 600;
  color: #333f57;
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
}

.footer-item .contact-links {
  margin-top: 5px;
}
.footer-item .contact-links li {
  margin-bottom: 15px;
  position: relative;
  text-transform: inherit;
}

.footer-item-2 .footer__desc {
  color: rgba(255, 255, 255, 0.6);
}
.footer-item-2 .footer__desc a {
  color: rgba(255, 255, 255, 0.6);
}
.footer-item-2 .footer__desc a:hover {
  color: #fff;
}

.footer-item-2 .list-items li {
  color: rgba(255, 255, 255, 0.6);
}
.footer-item-2 .list-items li a {
  color: rgba(255, 255, 255, 0.6);
}
.footer-item-2 .list-items li a:before {
  background-color: #fff;
}
.footer-item-2 .list-items li a:hover {
  color: #fff;
}

/* list-items */
.list-items.term-list li {
  display: inline-block;
  position: relative;
}
.list-items.term-list li + li {
  padding-left: 15px;
  margin-left: 5px;
}
.list-items.term-list li + li:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 2px;
  top: 55%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: rgba(127, 137, 150, 0.5);
}

/* list-items 2 */
.list-items.term-list-2 li {
  color: rgba(255, 255, 255, 0.6);
}
.list-items.term-list-2 li svg path {
  fill: #fff;
}
.list-items.term-list-2 li a {
  color: rgba(255, 255, 255, 0.6);
}
.list-items.term-list-2 li a:before {
  background-color: #fff;
}
.list-items.term-list-2 li a:hover {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-action-wrap .list-items {
    text-align: left !important;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .footer-action-wrap .list-items {
    text-align: left !important;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .footer-action-wrap .list-items {
    text-align: left !important;
    margin-top: 10px;
  }
}

/* footer-chosen-container */
.footer-chosen-container.user-chosen-select-container .chosen-container {
  width: 150px !important;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .footer-chosen-container.user-chosen-select-container .chosen-container {
    width: 140px !important;
  }
}

.footer-chosen-container.user-chosen-select-container .chosen-single {
  padding: 6px 12px;
}
.footer-chosen-container.user-chosen-select-container .chosen-single div {
  top: 8px;
}

.footer-chosen-container.user-chosen-select-container .chosen-search {
  padding-left: 5px;
}

.footer-chosen-container.user-chosen-select-container .chosen-drop {
  padding-right: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .footer-chosen-container.user-chosen-select-container {
    margin-top: 10px;
    -ms-flex-pack: start !important;
    justify-content: start !important;
  }
}

/* footer-chosen-container 2 */
.footer-chosen-container-2.user-chosen-select-container .chosen-single {
  background: rgba(255, 255, 255, 0.07);
  color: rgba(255, 255, 255, 0.6);
  padding: 6px 12px;
}
.footer-chosen-container-2.user-chosen-select-container .chosen-single span {
  color: rgba(255, 255, 255, 0.6);
}

.footer-chosen-container-2.user-chosen-select-container
  .chosen-container-active.chosen-with-drop
  .chosen-single {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.3);
  color: #808996;
}
.footer-chosen-container-2.user-chosen-select-container
  .chosen-container-active.chosen-with-drop
  .chosen-single
  span {
  color: #808996;
}

/* copy-right */
.copy-right {
  color: #808996;
  font-weight: 500;
  font-size: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copy-right {
    margin-top: 30px;
    padding-top: 30px;
    flex-direction: column;
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .copy-right {
    margin-top: 30px;
    padding-top: 30px;
    flex-direction: column;
    text-align: center;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .copy-right {
    margin-top: 30px;
    padding-top: 30px;
    flex-direction: column;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copy-right .copy__desc {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .copy-right .copy__desc {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .copy-right .copy__desc {
    margin-bottom: 10px;
  }
}
.copy-right .copy__desc span {
  color: #7b68ee;
  animation: heartBeat 1000ms infinite linear;
}
.copy-right .copy__desc a {
  color: #808996;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.copy-right .copy__desc a:hover {
  color: #7b68ee;
}

