.holder-card-container {
  background-color: white;
  border: 0px solid transparent;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2rem 0.5rem;
  margin: 1rem 0.4rem;
}

.tag {
  border: none;
  background-color: lightgray;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-right: 10px;
  padding: 2px;
  text-align: center;
  user-select: none;
  /* display: flex; */
}

.tagInput {
  border: 1px solid lightgray;
  border-radius: 2px;
  background-color: transparent;
  margin-top: 1rem;
}

.delete-button {
  border: none;
  background-color: lightgray;
  padding-left: 3px;
  margin: 0px;
  user-select: none;
}

.image-preview {
  position: relative;
}

.image-preview-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  color: difference;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.add-image-button {
  background: linear-gradient(270deg, #9f8fff, #7b68ee);
  color: white;
  border-radius: 100%;
  padding: 10px 12.25px 10px 12.25px;
  margin-bottom: 1rem;
}

.tagInput ::-webkit-input-placeholder {
  text-align: center;
}

.tagInput :-moz-placeholder {
  text-align: center;
}

.selectsedClass {
  width: 100%;
  display: wrap;
  /* height: 10px; */
  overflow-x: auto;
  overflow-y: none;
}
