/* .slick-prev {
  height: 100%;
  width: 2rem;
  /* background-color: rgb(221, 219, 223); */
/* padding: 10px 20px;
  z-index: 3;
  left: -75px; */
/* } */

img.img-fluid{
  object-fit: cover;
}
.slider button {
  opacity: 0;
  height: 100%;
  width: 5vw;
  z-index: 1;
}

.slider button:hover {
  opacity: 1;
  transition: opacity 0.2s ease 0s;
}

/* .slick-list {
  overflow: initial;
} */

/* .slick-prev:hover {
  background-color: rgb(231, 227, 235);
} */

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

/* //rgb(221, 219, 223) */
